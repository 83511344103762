import { parse } from 'query-string';
import { CHANGE_FILE } from '../../../_store/reducer';

const handleFileChange = (e, { dispatch }) => {
    e.preventDefault();
    const { name } = e.target;
    const { location: { search } } = window;
    const debugMode = 'debugMode' in parse(search);

    const reader = new FileReader();
    const file = e.target.files[0];
    if (!file) return '';

    if (debugMode) console.log(`New file selected: ${file.name}`);

    reader.onloadend = () => {
        dispatch({
            type: CHANGE_FILE,
            payload: { name, result: reader.result, file },
        });

        if (debugMode) console.log(`${name}File: ${reader.result.substring(0, 25)}`);
        if (debugMode) console.log(file.name);
        if (debugMode) console.log(name);
    };

    if (file) reader.readAsDataURL(file);

    return file;
};

export default handleFileChange;
