import React, { memo } from 'react';
import Schedule from '../../views/Schedule';
import iPhone8 from '../../../AppAssets/Phones/iphone8_upfront.png';

const Iphone83 = memo(() => (
    <div>
        <div className="screenshot screenshot-iphone8 screenshot-3">
            <h2>Explore the schedule and book classes!</h2>
            <div className="phone-outer">
                <img className="phoneMock" src={iPhone8} alt="iphone8" />
                <Schedule />
            </div>
        </div>
    </div>
));

Iphone83.displayName = 'Iphone83';

export default Iphone83;
