const initialUIState = {
    autoSave: null,
    isFormVisible: true,
    initialized: false,
    isDownloadModalVisible: false,
    isPreview: false,
    slideIndex: 1,
};

export default initialUIState;
