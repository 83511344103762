import resetAutoSave from './resetAutoSave';
import setQueryParams from './setQueryParams';

const setBrandName = ({
    brandName,
    dispatch,
    history,
    search,
    state,
}) => {
    if (brandName) {
        setQueryParams(history, state);

        resetAutoSave({
            brandName,
            dispatch,
            search,
            state,
        });
    }
};

export default setBrandName;
