/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, {
    useEffect,
    useRef,
    useState,
} from 'react';
import { ChromePicker } from 'react-color';
import { useController, useFormContext } from 'react-hook-form';
import TextInput from './TextInput';

const ColorInput = ({
    chromePicker,
    description,
    label,
    name,
    onChangeComplete,
    required,
}) => {
    const { control } = useFormContext();

    const {
        field: {
            onChange: onc,
            ref,
        },
    } = useController({
        name,
        control,
    });

    const [isPickerShown, setIsPickerShown] = useState(false);
    const colorPickerRef = useRef(null);

    const handleClickOutside = ({ target }: MouseEvent): void => {
        const { current } = colorPickerRef;
        if (current && !current.contains(target)) {
            setIsPickerShown(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [isPickerShown]);

    const toggleColorPicker = () => setIsPickerShown(!isPickerShown);
    const handleKeyDown = (e) => {
        const {
            ctrlKey,
            key,
            metaKey,
            target: { value, selectionStart, selectionEnd },
        } = e;

        const ignoredKeys = ['Backspace', 'ArrowLeft', 'ArrowRight', 'Tab'];

        if (ignoredKeys.includes(key) || metaKey || ctrlKey) {
            return;
        }

        const isValidCharacter = /[0-9A-F]/i.test(key);
        if (!isValidCharacter) {
            e.preventDefault();
            return;
        }

        const currentVal = value.split('');
        currentVal.splice(selectionStart, selectionEnd, key);
        const valueAfterNewKey = currentVal.join('');

        if (valueAfterNewKey.length > 6) {
            e.preventDefault();
        }
    };

    return (
        <TextInput
            className="form-field form-field--color"
            description={description}
            id={name}
            label={label}
            name={name}
            onChange={(e) => {
                if (onChangeComplete) onChangeComplete({ hex: e.target.value });
                onc(e);
            }}
            onKeyDown={handleKeyDown}
            placeholder="Hex Value"
            required={required}
        >
            {
                isPickerShown && (
                    <div
                        className="color-picker"
                        ref={colorPickerRef}
                    >
                        <div
                            style={{
                                position: 'fixed',
                                top: '0px',
                                right: '0px',
                                bottom: '0px',
                                left: '0px',
                                zIndex: '-1',
                            }}
                            onClick={toggleColorPicker}
                            role="button"
                            tabIndex={0}
                        >
                            <span className="visually-hidden">
                                Pick a Color
                            </span>
                        </div>
                        <ChromePicker
                            name={`${name}Pick`}
                            color={chromePicker}
                            onChange={(e) => {
                                if (onChangeComplete) onChangeComplete(e);
                                onc(e.hex);
                            }}
                            ref={ref}
                        />
                    </div>
                )
            }
            <button
                aria-label="Pick Color"
                className="btn-color"
                onClick={toggleColorPicker}
                style={{
                    backgroundColor: chromePicker,
                }}
                type="button"
            >
                <span className="visually-hidden">
                    Pick a Color
                </span>
            </button>
        </TextInput>
    );
};

export default ColorInput;
