import React, { Fragment, memo } from 'react';
import { useSelector } from 'react-redux';
import IphoneXCP from '../../_apps/mobile/layouts/iPhoneX/IphoneXCP';
import IphoneX1 from '../../_apps/mobile/layouts/iPhoneX/IphoneX1';
import IphoneX2 from '../../_apps/mobile/layouts/iPhoneX/IphoneX2';
import IphoneX3 from '../../_apps/mobile/layouts/iPhoneX/IphoneX3';
import IphoneX4 from '../../_apps/mobile/layouts/iPhoneX/IphoneX4';
import IphoneX5 from '../../_apps/mobile/layouts/iPhoneX/IphoneX5';
import IphoneX6 from '../../_apps/mobile/layouts/iPhoneX/IphoneX6';

const PreviewRowIPhoneX = memo(() => {
    const isPAS = useSelector(({ appState }) => appState.isPAS);

    return (
        <div className="preview__row ">
            <div id="iPhoneXScreenShot1" className="export-iphoneX">
                <IphoneXCP />
                <IphoneX1 />
            </div>
            <div id="iPhoneXScreenShot2" className="export-iphoneX">
                <IphoneXCP />
                <IphoneX2 />
            </div>
            <div id="iPhoneXScreenShot3" className="export-iphoneX">
                <IphoneXCP />
                <IphoneX3 />
            </div>

            {
                isPAS && (
                    <Fragment>
                        <div id="iPhoneXScreenShot4" className="export-iphoneX">
                            <IphoneXCP />
                            <IphoneX4 />
                        </div>
                        <div id="iPhoneXScreenShot5" className="export-iphoneX">
                            <IphoneXCP />
                            <IphoneX5 />
                        </div>
                    </Fragment>
                )
            }

            <div id="iPhoneXScreenShot6" className="export-iphoneX">
                <IphoneXCP />
                <IphoneX6 />
            </div>
        </div>
    );
});

PreviewRowIPhoneX.displayName = 'PreviewRowIPhoneX';

export default PreviewRowIPhoneX;
