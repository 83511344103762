import { parse } from 'query-string';
import setLocalStorage from './setLocalStorage';
import { SET_AUTO_SAVE } from '../_store/reducer';

const resetAutoSave = ({
    brandName,
    dispatch,
    search,
    state,
}) => {
    const nextAutoSave = setInterval(() => {
        const debugMode = 'debugMode' in parse(search);

        if (debugMode) console.log(brandName, 'Setting LocalStorage');

        setLocalStorage(brandName, state);
    }, 30000);

    dispatch({
        type: SET_AUTO_SAVE,
        payload: { autoSave: nextAutoSave },
    });
};

export default resetAutoSave;
