import React, { memo } from 'react';
import Home from '../../views/Home';
import iPhoneXAngle from '../../../AppAssets/Phones/iphoneX_angle.png';

const IphoneX2 = memo(() => (
    <div>
        <div className="screenshot screenshot-2">
            <h2>Find everything you need on your personalized homepage!</h2>
            <div className="colorAngle" />
            <div className="phone-outer">
                <img className="phoneMock" src={iPhoneXAngle} alt="iPhone X" />
                <Home />
            </div>
        </div>

    </div>
));

IphoneX2.displayName = 'IphoneX2';

export default IphoneX2;
