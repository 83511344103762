import React, { memo } from 'react';
import { useSelector } from 'react-redux';
import AndroidPlay from '../../_apps/mobile/layouts/Android/AndroidPlay';
import defaultMark from '../../_apps/AppAssets/Icons/DefaultIcon.png';

const PreviewRowIPhoneX = memo(() => {
    const lightLogoMark = useSelector(({ appState }) => appState.lightLogoMark);
    const appIconLogoMark = useSelector(({ appState }) => appState.appIconLogoMark);
    const appIconBackColor = useSelector(({ appState }) => appState.appIconBackColor);

    return (
        <div className="preview__row">
            <div id="androidplaygraphic" className="export-androidplaygraphic">
                <AndroidPlay />
            </div>
            <div
                id="AppIcon"
                className="app-icon export-appicon"
                style={{
                    backgroundColor: appIconBackColor,
                }}
            >
                <div
                    className="app-icon__image"
                    style={{
                        backgroundImage: `url("${appIconLogoMark || lightLogoMark || defaultMark}")`,
                    }}
                />
            </div>
        </div>
    );
});

PreviewRowIPhoneX.displayName = 'PreviewRowIPhoneX';

export default PreviewRowIPhoneX;
