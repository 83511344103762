import React, { memo } from 'react';
import PaS from '../../views/PaS';
import iPhoneXFront from '../../../AppAssets/Phones/iphoneX_upfront.png';

const IphoneX4 = memo(() => (
    <div>
        <div className="screenshot screenshot-4">
            <div className="phone-outer">
                <img className="phoneMock" src={iPhoneXFront} alt="iPhone X" />
                <PaS />
            </div>
        </div>
    </div>
));

IphoneX4.displayName = 'IphoneX4';

export default IphoneX4;
