import React, { memo } from 'react';
import previewPhoneLeft from '../../_apps/AppAssets/Phones/preview—android-left.png';
import previewPhoneFront from '../../_apps/AppAssets/Phones/iphoneX_upfront.png';
import previewPhoneRight from '../../_apps/AppAssets/Phones/preview—iphoneX-right.png';

const SlideshowItem = memo(({
    index,
    isActive,
    left,
    middle,
    right,
}) => (
    <div className={`slideshow__item slideAnimation ${isActive ? 'active' : 'inactive'} slide-${index}`}>
        <div className="slideshow__item__wrapper">
            <div className="slideshow__item__wrapper__background" />
            <div className="screens">
                <div className="slideshow__item__wrapper-phone1 phone-outer">
                    <img className="phoneMock" src={previewPhoneLeft} alt="" />
                    {left}
                </div>
                <div className="slideshow__item__wrapper-phone2 phone-outer">
                    <img className="phoneMock" src={previewPhoneFront} alt="" />
                    {middle}
                </div>
                <div className="slideshow__item__wrapper-phone3 phone-outer">
                    <img className="phoneMock" src={previewPhoneRight} alt="" />
                    {right}
                </div>
            </div>
        </div>
    </div>
));

SlideshowItem.displayName = 'SlideshowItem';

export default SlideshowItem;
