import React, { memo } from 'react';
import PaS from '../../views/PaS';
import iPhone8 from '../../../AppAssets/Phones/iphone8_upfront.png';

const Iphone85 = memo(() => (
    <div>
        <div className="screenshot screenshot-iphone8 screenshot-5">
            <h2>Seamlessly book, change and reserve your favorite spots!</h2>
            <div className="phone-outer">
                <img className="phoneMock" src={iPhone8} alt="iphone8" />
                <PaS />
            </div>
        </div>

    </div>
));

Iphone85.displayName = 'Iphone85';
export default Iphone85;
