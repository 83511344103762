import React from 'react';

const AndroidFilter = () => (

    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0.75 13.5833V15.4167H6.25V13.5833H0.75ZM0.75 2.58333V4.41667H9.91667V2.58333H0.75ZM9.91667 17.25V15.4167H17.25V13.5833H9.91667V11.75H8.08333V17.25H9.91667ZM4.41667 6.25V8.08333H0.75V9.91667H4.41667V11.75H6.25V6.25H4.41667ZM17.25 9.91667V8.08333H8.08333V9.91667H17.25ZM11.75 6.25H13.5833V4.41667H17.25V2.58333H13.5833V0.75H11.75V6.25Z" />
    </svg>

);

export default AndroidFilter;
