import React, {
    memo,
    useEffect,
    useRef,
    useState,
} from 'react';
import { useSelector } from 'react-redux';
import Close from '../../AppAssets/Icons/svg/Close';
import defaultMark from '../../AppAssets/Icons/DefaultIcon.png';
import checks from '../../AppAssets/Icons/achievements-Checkmark.png';
import instructors from '../../AppAssets/Icons/achievements-Instructor.png';
import studios from '../../AppAssets/Icons/achievements-Studio.png';
import locations from '../../AppAssets/Icons/achievements-Location.png';

const Achievements = memo(() => {
    const lightLogoMark = useSelector(({ appState }) => appState.lightLogoMark);
    const studio1 = useSelector(({ appState }) => appState.studio1);
    const fontSize = 17;
    const lineHeight = 1.8;

    const displayedStudioName = studio1.length > 20
        ? `${studio1.substring(0, 20)}...`
        : studio1;

    const textRef = useRef(null);
    const [
        { textWidth, textHeight },
        setTextBBox,
    ] = useState({ textWidth: 20, textHeight: 20 });

    useEffect(() => {
        const { current } = textRef;

        if (current && current.getBBox) {
            const { width, height } = current.getBBox();

            setTextBBox({
                textHeight: height,
                textWidth: Math.max(fontSize * 8, width),
            });
        }
    }, [studio1]);

    return (
        <div>
            <div className="view view-achievements achievements">
                {/* <!-- header   --> */}
                <div className="achievements-header">
                    <Close />
                    <div>
                        <img className="clientMark" src={lightLogoMark || defaultMark} alt="client mark" />
                        <h1>
                            <span>1</span>
                            00
                        </h1>
                        <p>
                            Classes completed!
                            Way to go!
                        </p>
                    </div>
                </div>
                {/* <!--       Tiles --> */}
                <section className="tileRow">
                    <div className="tile">
                        <img src={checks} alt="" />
                        <h3 className="nowrap">January 1, 2019</h3>
                        <p className="nowrap">Member Since</p>
                    </div>
                    <div className="tile">
                        <img src={instructors} alt="" />
                        <h2>15</h2>
                        <p className="nowrap">Instructors Taken</p>
                    </div>
                    <div className="tile">
                        <img src={studios} alt="" />
                        <svg
                            width="100%"
                            height={fontSize * lineHeight}
                            viewBox={`0 0 ${textWidth} ${textHeight}`}
                        >
                            <text
                                className="h3 nowrap"
                                textAnchor="middle"
                                x="50%"
                                y="50%"
                                height="100%"
                                width="100%"
                                ref={textRef}
                            >
                                {displayedStudioName || 'Dupont'}
                            </text>
                        </svg>
                        <p className="nowrap">Most Visited Studio</p>
                    </div>
                    <div className="tile tile-visited">
                        <img src={locations} alt="" />
                        <div className="">
                            <h2>3</h2>
                            <p>Studios Visited</p>
                        </div>
                    </div>
                </section>
                <div className="button-row">
                    <button type="button" className="btn-secondary">Share</button>
                </div>
            </div>

        </div>
    );
});

Achievements.displayName = 'Achievements';

export default Achievements;
