import React from 'react';

const AndroidDownArrow = () => (

    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M3 8L12 17L21 8H3Z" />
    </svg>

);

export default AndroidDownArrow;
