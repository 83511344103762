import React, { Fragment, memo } from 'react';
import { useSelector } from 'react-redux';
import Welcome from '../../_apps/mobile/views/Welcome';
import Home from '../../_apps/mobile/views/Home';
import Achievements from '../../_apps/mobile/views/Achievements';
import Schedule from '../../_apps/mobile/views/Schedule';
import PaS from '../../_apps/mobile/views/PaS';
import CreateAccount from '../../_apps/mobile/views/CreateAccount';
import IosHome from '../../_apps/mobile/views/IosHome';
import SlideshowItem from './SlideshowItem';
import SlideshowNav from './SlideshowNav';

const Slideshow = memo(() => {
    const slideIndex = useSelector(({ uiState }) => uiState.slideIndex);
    const isPAS = useSelector(({ appState }) => appState.isPAS);

    const slides = [
        {
            left: <Welcome device="Android" />,
            middle: <Home />,
            right: <Achievements />,
        },
        {
            left: isPAS ? <PaS /> : <CreateAccount />,
            middle: <IosHome />,
            right: <Schedule />,
        },
        {
            left: <Schedule />,
            middle: <PaS />,
            right: <CreateAccount />,
        },
    ];
    return (
        <Fragment>
            <section className="slideshow">
                <SlideshowItem
                    index={0}
                    isActive={slideIndex === 1}
                    {...slides[0]}
                />

                <SlideshowItem
                    index={1}
                    isActive={slideIndex === 2}
                    {...slides[1]}
                />

                {
                    isPAS && (
                        <SlideshowItem
                            index={2}
                            isActive={slideIndex === 3}
                            {...slides[2]}
                        />
                    )
                }
            </section>
            <SlideshowNav />
        </Fragment>
    );
});
Slideshow.displayName = 'Slideshow';
export default Slideshow;
