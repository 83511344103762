import React from 'react';

const Close = () => (

    <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M20.745 0.310903C21.0703 0.636437 21.0701 1.16407 20.7446 1.48941L2.05571 20.1672C1.73018 20.4925 1.20254 20.4924 0.877203 20.1668C0.551863 19.8413 0.55202 19.3137 0.877554 18.9883L19.5664 0.310553C19.892 -0.0147873 20.4196 -0.0146303 20.745 0.310903Z" />
        <path fillRule="evenodd" clipRule="evenodd" d="M0.877203 0.310903C1.20254 -0.0146303 1.73018 -0.0147873 2.05571 0.310553L20.7446 18.9883C21.0701 19.3137 21.0703 19.8413 20.745 20.1668C20.4196 20.4924 19.892 20.4925 19.5664 20.1672L0.877554 1.48941C0.55202 1.16407 0.551863 0.636437 0.877203 0.310903Z" />
    </svg>

);

export default Close;
