import React from 'react';
import { useController, useFormContext } from 'react-hook-form';
import FormField from './FormField';

const yesIcon = '<svg viewBox="0 0 10 8" fill="#757475" xmlns="http://www.w3.org/2000/svg"> <path d="M3.87348 8L0.252536 4.99422C-0.069326 4.75692 -0.069326 4.28232 0.172071 4.04502C0.413467 3.72863 0.89626 3.72863 1.21812 3.96593L3.71255 6.02251L8.70141 0.248249C8.94281 -0.0681491 9.4256 -0.0681491 9.74746 0.169149C10.0693 0.406448 10.0693 0.881045 9.82793 1.19744L3.87348 8Z" /> </svg>';
const noIcon = '<svg viewBox="0 0 30 30" stroke="#757475" xmlns="http://www.w3.org/2000/svg"> <path d="M2.8749125 2.0704625L25.874875 25.0705" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/> <path d="M25.874875 2.0704625L2.8749125 25.0705" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/> </svg>';

const ToggleInput = ({
    description,
    isValid,
    label,
    name,
    onChange,
}) => {
    const { control } = useFormContext();

    const {
        field: {
            onChange: onc,
            ref,
            value,
        },
    } = useController({
        name,
        control,
    });

    return (
        <FormField
            description={description}
            isValid={isValid}
            label={label}
            name={name}
        >
            <div className="form-field__input toggle">
                <input
                    name={name}
                    ref={ref}
                    onChange={(e) => {
                        onc(e.target.checked);
                        if (onChange) onChange(e);
                    }}
                    type="checkbox"
                    checked={value}
                />

                <div
                    className={`toggle__wrapper toggle__wrapper--${value ? 'on' : 'off'}`}
                    style={{
                        backgroundImage: `url(data:image/svg+xml;base64,${Buffer.from(value ? yesIcon : noIcon).toString('base64')})`,
                    }}
                >
                    <div className="toggle__indicator" />
                </div>

                <span className="toggle__state-description">{value ? 'Yes' : 'No'}</span>
            </div>
        </FormField>
    );
};

export default ToggleInput;
