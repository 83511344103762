import React, { memo } from 'react';
import PaS from '../../views/PaS';
import iPhoneXFront from '../../../AppAssets/Phones/iphoneX_upfront.png';

const IphoneX5 = memo(() => (
    <div>
        <div className="screenshot screenshot-5">
            <h2>Seamlessly book, change and reserve your favorite spots!</h2>
            <div className="phone-outer">
                <img className="phoneMock" src={iPhoneXFront} alt="iPhone X" />
                <PaS />
            </div>
        </div>

    </div>
));

IphoneX5.displayName = 'IphoneX5';

export default IphoneX5;
