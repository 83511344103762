import React from 'react';

const AndroidTopBar = () => (

    <svg width="360" height="24" viewBox="0 0 360 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g opacity="0.9">
            <path d="M321.719 17H320.064V9.00879L317.624 9.84277V8.44141L321.507 7.0127H321.719V17Z" />
            <path d="M331.87 17H325.178V15.8584L328.493 12.2422C328.972 11.709 329.311 11.2646 329.512 10.9092C329.717 10.5492 329.819 10.1891 329.819 9.8291C329.819 9.35514 329.685 8.97233 329.416 8.68066C329.152 8.389 328.794 8.24316 328.343 8.24316C327.805 8.24316 327.388 8.40723 327.092 8.73535C326.796 9.06348 326.647 9.51237 326.647 10.082H324.986C324.986 9.47591 325.123 8.93132 325.396 8.44824C325.674 7.96061 326.069 7.58236 326.579 7.31348C327.094 7.0446 327.687 6.91016 328.356 6.91016C329.323 6.91016 330.084 7.15397 330.64 7.6416C331.2 8.12467 331.48 8.7946 331.48 9.65137C331.48 10.1481 331.339 10.6699 331.057 11.2168C330.779 11.7591 330.321 12.3766 329.683 13.0693L327.249 15.6738H331.87V17Z" />
            <path d="M334.242 15.25C334.552 15.25 334.791 15.3389 334.96 15.5166C335.129 15.6898 335.213 15.9085 335.213 16.1729C335.213 16.4326 335.129 16.6491 334.96 16.8223C334.791 16.9909 334.552 17.0752 334.242 17.0752C333.946 17.0752 333.711 16.9909 333.538 16.8223C333.365 16.6536 333.278 16.4372 333.278 16.1729C333.278 15.9085 333.363 15.6898 333.531 15.5166C333.7 15.3389 333.937 15.25 334.242 15.25ZM334.242 9.44629C334.552 9.44629 334.791 9.53516 334.96 9.71289C335.129 9.88607 335.213 10.1048 335.213 10.3691C335.213 10.6289 335.129 10.8454 334.96 11.0186C334.791 11.1872 334.552 11.2715 334.242 11.2715C333.946 11.2715 333.711 11.1872 333.538 11.0186C333.365 10.8499 333.278 10.6335 333.278 10.3691C333.278 10.1048 333.363 9.88607 333.531 9.71289C333.7 9.53516 333.937 9.44629 334.242 9.44629Z" />
            <path d="M338.774 11.2646H339.786C340.297 11.2601 340.7 11.1279 340.996 10.8682C341.297 10.6084 341.447 10.2324 341.447 9.74023C341.447 9.26628 341.322 8.89941 341.071 8.63965C340.825 8.37533 340.447 8.24316 339.937 8.24316C339.49 8.24316 339.123 8.37305 338.836 8.63281C338.549 8.88802 338.405 9.22298 338.405 9.6377H336.744C336.744 9.12728 336.879 8.66243 337.147 8.24316C337.421 7.82389 337.799 7.49805 338.282 7.26562C338.77 7.02865 339.314 6.91016 339.916 6.91016C340.91 6.91016 341.689 7.16081 342.254 7.66211C342.824 8.15885 343.108 8.85156 343.108 9.74023C343.108 10.1868 342.965 10.6084 342.678 11.0049C342.395 11.3968 342.028 11.693 341.577 11.8936C342.124 12.0804 342.539 12.3743 342.821 12.7754C343.108 13.1764 343.252 13.6549 343.252 14.2109C343.252 15.1042 342.944 15.8151 342.329 16.3438C341.718 16.8724 340.914 17.1367 339.916 17.1367C338.959 17.1367 338.175 16.8815 337.564 16.3711C336.954 15.8607 336.648 15.1816 336.648 14.334H338.31C338.31 14.7715 338.455 15.127 338.747 15.4004C339.043 15.6738 339.44 15.8105 339.937 15.8105C340.451 15.8105 340.857 15.6738 341.153 15.4004C341.45 15.127 341.598 14.7305 341.598 14.2109C341.598 13.6868 341.443 13.2835 341.133 13.001C340.823 12.7184 340.363 12.5771 339.752 12.5771H338.774V11.2646Z" />
            <path d="M351.298 12.8096C351.298 14.2406 351.029 15.3206 350.491 16.0498C349.958 16.7744 349.145 17.1367 348.051 17.1367C346.975 17.1367 346.164 16.7835 345.617 16.0771C345.075 15.3662 344.797 14.3158 344.783 12.9258V11.2031C344.783 9.77214 345.05 8.69889 345.583 7.9834C346.121 7.2679 346.939 6.91016 348.037 6.91016C349.126 6.91016 349.938 7.25879 350.471 7.95605C351.008 8.65332 351.284 9.69694 351.298 11.0869V12.8096ZM349.637 10.9502C349.637 10.016 349.509 9.33236 349.254 8.89941C348.999 8.46191 348.593 8.24316 348.037 8.24316C347.495 8.24316 347.096 8.45052 346.841 8.86523C346.586 9.27539 346.451 9.91797 346.438 10.793V13.0488C346.438 13.9785 346.567 14.6712 346.827 15.127C347.087 15.5827 347.495 15.8105 348.051 15.8105C348.584 15.8105 348.978 15.6009 349.233 15.1816C349.489 14.7578 349.623 14.097 349.637 13.1992V10.9502Z" />
        </g>
        <path fillRule="evenodd" clipRule="evenodd" d="M306.022 5.875V5L303.022 5V5.875H300.022V19H309.022V5.875H306.022Z" />
        <path fillRule="evenodd" clipRule="evenodd" d="M277.022 19H291.022V5L277.022 19Z" />
        <path fillRule="evenodd" clipRule="evenodd" d="M257 8.01593L266.023 19L275.045 8.01593C272.534 6.12256 269.41 5 266.023 5C262.636 5 259.511 6.12256 257 8.01593Z" />
    </svg>

);

export default AndroidTopBar;
