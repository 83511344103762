import React, { memo } from 'react';
import { useSelector } from 'react-redux';
import IosTopBar from '../../AppAssets/Icons/svg/IosTopBar';
import AndroidTopBar from '../../AppAssets/Icons/svg/AndroidTopBar';
import defaultMark from '../../AppAssets/Icons/DefaultIcon.png';
import poweredby from '../../AppAssets/Icons/poweredby.png';
import androidForward from '../../AppAssets/Icons/androidForwardArrow.png';

const Welcome = memo(({ device }) => {
    const lightLogoMark = useSelector(({ appState }) => appState.lightLogoMark);
    const brandName = useSelector(({ appState }) => appState.brandName);

    return (
        <div>
            <div className="view view-welcome welcome">

                <section className="welcome-top">
                    {device !== 'Android' && <IosTopBar />}
                    {device === 'Android' && <AndroidTopBar />}
                    <span className="welcome-top-skip">
                        Skip for Now
                        <img src={androidForward} alt="" />
                    </span>
                    <div>
                        <img className="clientMark" src={lightLogoMark || defaultMark} alt="client mark" />
                        <h1>
                            Welcome to
                            {' '}
                            <span>{brandName || 'Mariana Tek'}</span>
                        </h1>
                        <p>
                            Track your reservations, celebrate your achievements,
                            and check in, all within the app.
                        </p>
                    </div>
                </section>
                <section className="welcome-bottom">
                    <button type="button" className="btn-secondary">Log In</button>
                    <button type="button" className="btn-primary">Create Account</button>
                    <img className="welcome-bottom-poweredBy" src={poweredby} alt="Powered-By" border="0" />
                    <div className="colorAngle-bg" />
                </section>
            </div>
        </div>
    );
});

Welcome.displayName = 'Welcome';

export default Welcome;
