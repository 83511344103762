import { parse } from 'query-string';
import { CHANGE_COLOR } from '../../../_store/reducer';
import setCSSColorProperties from '../../../_utilities/setCSSColorProperties';

const handleColorChange = ({ hex }, { dispatch, modifier }) => {
    const { location: { search } } = window;
    const debugMode = 'debugMode' in parse(search);

    if (debugMode) console.log(hex);

    const containsHash = hex.includes('#');
    let hashedHex = hex;
    if (!containsHash) hashedHex = `#${hex}`;

    dispatch({
        type: CHANGE_COLOR,
        payload: { hex: hashedHex, modifier },
    });

    setCSSColorProperties({ hex: hashedHex, modifier });
};

export default handleColorChange;
