import React from 'react';
import IosTopBar from '../../AppAssets/Icons/svg/IosTopBar';
import AndroidTopBar from '../../AppAssets/Icons/svg/AndroidTopBar';
import BackArrow from '../../AppAssets/Icons/svg/BackArrow';
import AndroidBackArrow from '../../AppAssets/Icons/svg/AndroidBackArrow';
import Info from '../../AppAssets/Icons/svg/Info';

const PaS = ({ device }) => (
    <div>
        <div className="view view-pas pas">
            {/* <!-- header   --> */}
            <div className="pas-header">
                {device !== 'Android' && <IosTopBar />}
                {device === 'Android' && <AndroidTopBar />}
                <div className="pas-header-titleRow">
                    {device !== 'Android' && <BackArrow />}
                    {device === 'Android' && <AndroidBackArrow />}
                    <h1>Pick Your Spot</h1>
                    <Info />
                </div>
            </div>
            {/* <!--       pas layout --> */}
            <div className="pas-layout">
                <div className="pas-layout-row">
                    <div>00</div>
                    <div>01</div>
                </div>
                <div className="pas-layout-row">
                    <div>02</div>
                    <div className="inactive">03</div>
                    <div>04</div>
                    <div className="active">05</div>
                    <div>06</div>
                    <div>07</div>
                    <div>08</div>
                    <div>09</div>
                </div>
                <div className="pas-layout-row">
                    <div>10</div>
                    <div>11</div>
                    <div>12</div>
                    <div>13</div>
                    <div>14</div>
                    <div>15</div>
                    <div className="inactive">16</div>
                    <div>17</div>
                </div>
                <div className="pas-layout-row">
                    <div>18</div>
                    <div>19</div>
                    <div>20</div>
                    <div>21</div>
                    <div>22</div>
                    <div>23</div>
                    <div>24</div>
                    <div>25</div>
                </div>
                <div className="pas-layout-row">
                    <div className="inactive">26</div>
                    <div>27</div>
                    <div>28</div>
                    <div className="inactive">29</div>
                    <div>30</div>
                    <div>31</div>
                    <div>32</div>
                    <div>33</div>
                </div>
                <div className="pas-layout-row">
                    <div>34</div>
                    <div>35</div>
                    <div>36</div>
                    <div>37</div>
                    <div>38</div>
                </div>
                <div className="pas-layout-row">
                    <div>39</div>
                    <div className="inactive">40</div>
                    <div>41</div>
                </div>
                <div className="pas-layout-row">
                    <div>42</div>
                    <div>43</div>
                </div>
                <div className="pas-layout-row">
                    <div className="hidden">00</div>
                </div>
                <div className="pas-layout-row">
                    <div className="hidden">00</div>
                    <div>44</div>
                </div>
                <div className="pas-layout-row">
                    <div className="hidden" />
                    <div>45</div>
                    <div>46</div>
                    <div>47</div>
                    <div>48</div>
                    <div>49</div>
                    <div>50</div>
                    <div>51</div>
                </div>
                <div className="pas-layout-row">
                    <div className="hidden" />
                    <div>52</div>
                    <div>53</div>
                    <div>54</div>
                    <div>55</div>
                    <div className="inactive">56</div>
                    <div>57</div>
                    <div>58</div>
                </div>
                <div className="pas-layout-row">
                    <div className="hidden">00</div>
                    <div>59</div>
                    <div>60</div>
                    <div>61</div>
                    <div className="inactive">62</div>
                    <div>63</div>
                    <div>64</div>
                    <div>65</div>
                </div>
                <div className="pas-layout-row">
                    <div className="hidden">00</div>
                    <div>66</div>
                    <div>67</div>
                    <div>68</div>
                    <div>69</div>
                    <div>70</div>
                    <div className="inactive">71</div>
                    <div>72</div>
                </div>
            </div>
        </div>
    </div>
);
export default PaS;
