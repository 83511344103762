import { UPDATE_APP } from '../../../_store/reducer';
import setCSSColorProperties from '../../../_utilities/setCSSColorProperties';

const handleChange = (e, { dispatch }) => {
    const { name, type, value } = e.target;

    const isColor = name.includes('Color');
    const isCheckbox = type === 'checkbox';

    if (isColor) {
        const valueAsHex = value[0] === '#' ? value : `#${value}`;

        setCSSColorProperties({
            hex: valueAsHex,
            modifier: name.replace('Color', ''),
        });
    }

    dispatch({
        type: UPDATE_APP,
        payload: {
            name,
            value,
            isColor,
            isCheckbox,
        },
    });
};

export default handleChange;
