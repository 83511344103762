import { parse } from 'query-string';
import { REMOVE_FILE } from '../../../_store/reducer';

const handleFileRemove = (e, { dispatch }) => {
    e.preventDefault();
    const { name } = e.target;
    const { location: { search } } = window;
    const debugMode = 'debugMode' in parse(search);

    // set files attribute because file inputs only detect change with new file name
    document.querySelector(`input[name=${name}]`).files = new DataTransfer().files;

    if (debugMode) console.log(name);

    dispatch({
        type: REMOVE_FILE,
        payload: { name },
    });
};

export default handleFileRemove;
