import { parse, stringify } from 'query-string';

const setQueryParams = (history, state) => {
    const {
        appIconBackColor,
        brandName,
        class1,
        class2,
        class3,
        instructor1,
        instructor2,
        instructor3,
        isPAS,
        primaryColor,
        region,
        secondaryColor,
        studio1,
        studio2,
        studio3,
    } = state;

    if (brandName) {
        const savedState = ({
            appIconBackColor,
            brandName,
            class1,
            class2,
            class3,
            instructor1,
            instructor2,
            instructor3,
            isPAS,
            primaryColor,
            region,
            secondaryColor,
            studio1,
            studio2,
            studio3,
        });

        const urlSearchParams = Object.keys(savedState)
            .reduce((acc, curr) => ({
                ...acc,
                [curr]: savedState[curr] === '' ? undefined : savedState[curr],
            }), {});

        const { location: { search } } = window;

        const debugMode = 'debugMode' in parse(search);

        history.replace(
            `/${brandName}?${stringify({ ...urlSearchParams, debugMode })}`,
        );
    }
};

export default setQueryParams;
