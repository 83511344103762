import React, { memo } from 'react';
import { useSelector } from 'react-redux';
import getForegroundColor from '../../_utilities/getForegroundColor';
import { designTheme } from '../../_store/initialAppState';

const ButtonDot = memo(({
    isActive,
    onClick,
}) => {
    const secondaryColor = useSelector(({ appState }) => appState.secondaryColor);

    return (
        <button
            type="button"
            aria-label="slide"
            className={`btn-dot ${isActive ? 'active' : ''}`}
            onClick={onClick}
            style={{
                backgroundColor: getForegroundColor(
                    secondaryColor || designTheme.secondaryColorPick,
                ),
            }}
        />
    );
});

ButtonDot.displayName = 'ButtonDot';

export default ButtonDot;
