import React, { memo } from 'react';
import Schedule from '../../views/Schedule';
import defaultPixel3 from '../../../AppAssets/Phones/android-upfront.png';

const Android3 = memo(() => (
    <div>
        <div className="screenshot screenshot-android screenshot-3">
            <h2>Explore the schedule and book classes!</h2>
            <div className="phone-outer">
                <img className="phoneMock" src={defaultPixel3} alt="Pixel 3" />
                <Schedule device="Android" />
            </div>
        </div>
    </div>
));

Android3.displayName = 'Android3';

export default Android3;
