import React, { memo } from 'react';
import Home from '../../views/Home';
import defaultPixel3Angle from '../../../AppAssets/Phones/android_angle.png';

const Android2 = memo(() => (
    <div>
        <div className="screenshot screenshot-android screenshot-2">
            <h2>Find everything you need on your personalized homepage!</h2>
            <div className="colorAngle" />
            <div className="phone-outer">
                <img className="phoneMock" src={defaultPixel3Angle} alt="Pixel 3" />
                <Home device="Android" />
            </div>
        </div>

    </div>
));

Android2.displayName = 'Android2';

export default Android2;
