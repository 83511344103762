import { parse } from 'query-string';
import { CHANGE_FILE } from '../../../_store/reducer';

const handleFileDrop = (e, { dispatch }) => {
    e.preventDefault();
    const { dataset: { name } } = e.target.closest('.form-image-drop');
    const { location: { search } } = window;
    const debugMode = 'debugMode' in parse(search);

    if (debugMode) console.log(name);

    const reader = new FileReader();

    if (e.dataTransfer.items) {
        let file;
        for (let i = 0; i < e.dataTransfer.items.length; i += 1) {
            // If dropped items aren't files, reject them
            if (e.dataTransfer.items[i].kind === 'file') {
                file = e.dataTransfer.items[i].getAsFile();
                if (debugMode) console.log(`... file[${i}].name = ${e.name}`);
            }
        }

        reader.onloadend = () => {
            dispatch({
                type: CHANGE_FILE,
                payload: { name, result: reader.result, file },
            });
            if (debugMode) console.log(file.name);
            if (debugMode) console.log(name);
        };

        reader.readAsDataURL(file);
    }
};

export default handleFileDrop;
