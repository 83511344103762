import React from 'react';

const IosTopBar = () => (
    <svg width="375" height="44" viewBox="0 0 375 44" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect opacity="0.35" x="335.834" y="17.8335" width="21" height="10.3333" rx="2.16667" stroke="var(--primaryForeground)" />
        <path opacity="0.4" d="M358.334 21.0002V25.0002C359.139 24.6615 359.662 23.8734 359.662 23.0002C359.662 22.1271 359.139 21.339 358.334 21.0002" />
        <rect x="337.334" y="19.3335" width="18" height="7.33333" rx="1.33333" />
        <path fillRule="evenodd" clipRule="evenodd" d="M322.667 19.615C324.892 19.6151 327.031 20.4693 328.644 22.0008C328.765 22.1191 328.959 22.1176 329.079 21.9975L330.239 20.8272C330.3 20.7663 330.334 20.6837 330.333 20.5979C330.333 20.5121 330.298 20.4299 330.237 20.3697C326.005 16.3175 319.329 16.3175 315.097 20.3697C315.035 20.4299 315.001 20.512 315 20.5978C314.999 20.6837 315.033 20.7662 315.094 20.8272L316.255 21.9975C316.374 22.1178 316.568 22.1193 316.69 22.0008C318.302 20.4692 320.442 19.615 322.667 19.615ZM322.667 23.4226C323.889 23.4226 325.068 23.8765 325.974 24.6963C326.097 24.8126 326.29 24.8101 326.409 24.6906L327.569 23.5203C327.63 23.4589 327.663 23.3756 327.663 23.2891C327.662 23.2025 327.626 23.12 327.564 23.0598C324.805 20.4954 320.532 20.4954 317.773 23.0598C317.71 23.12 317.675 23.2026 317.674 23.2891C317.673 23.3757 317.707 23.459 317.768 23.5203L318.927 24.6906C319.047 24.8101 319.24 24.8126 319.362 24.6963C320.268 23.877 321.446 23.4231 322.667 23.4226ZM324.989 25.9845C324.991 26.0712 324.957 26.1549 324.895 26.2157L322.89 28.2377C322.831 28.2971 322.751 28.3306 322.667 28.3306C322.583 28.3306 322.503 28.2971 322.445 28.2377L320.439 26.2157C320.377 26.1549 320.343 26.0712 320.345 25.9844C320.347 25.8976 320.384 25.8154 320.449 25.7573C321.729 24.675 323.605 24.675 324.886 25.7573C324.95 25.8155 324.988 25.8977 324.989 25.9845Z" />
        <path fillRule="evenodd" clipRule="evenodd" d="M309 17.6665H308C307.448 17.6665 307 18.1142 307 18.6665V27.3332C307 27.8855 307.448 28.3332 308 28.3332H309C309.552 28.3332 310 27.8855 310 27.3332V18.6665C310 18.1142 309.552 17.6665 309 17.6665ZM303.333 19.9998H304.333C304.886 19.9998 305.333 20.4476 305.333 20.9998V27.3332C305.333 27.8855 304.886 28.3332 304.333 28.3332H303.333C302.781 28.3332 302.333 27.8855 302.333 27.3332V20.9998C302.333 20.4476 302.781 19.9998 303.333 19.9998ZM299.667 22.3332H298.667C298.114 22.3332 297.667 22.7809 297.667 23.3332V27.3332C297.667 27.8855 298.114 28.3332 298.667 28.3332H299.667C300.219 28.3332 300.667 27.8855 300.667 27.3332V23.3332C300.667 22.7809 300.219 22.3332 299.667 22.3332ZM295 24.3332H294C293.448 24.3332 293 24.7809 293 25.3332V27.3332C293 27.8855 293.448 28.3332 294 28.3332H295C295.552 28.3332 296 27.8855 296 27.3332V25.3332C296 24.7809 295.552 24.3332 295 24.3332Z" />
        <path d="M25.856 17.1675C23.5635 17.1675 21.9302 18.7349 21.9302 20.8809V20.8955C21.9302 22.9023 23.3511 24.3818 25.3799 24.3818C26.8301 24.3818 27.7529 23.6421 28.1411 22.8071H28.2876C28.251 24.9458 27.5552 26.7109 25.812 26.7109C24.8818 26.7109 24.23 26.2275 23.9517 25.4878L23.9297 25.4146H22.0693L22.084 25.4951C22.4209 27.1138 23.8711 28.2637 25.812 28.2637C28.4707 28.2637 30.0747 26.1543 30.0747 22.5947V22.5801C30.0747 18.7715 28.1118 17.1675 25.856 17.1675ZM25.8486 22.9316C24.6475 22.9316 23.7759 22.0527 23.7759 20.8296V20.8149C23.7759 19.6357 24.7061 18.7056 25.8706 18.7056C27.0425 18.7056 27.958 19.6504 27.958 20.8589V20.8735C27.958 22.0674 27.0425 22.9316 25.8486 22.9316Z" />
        <path d="M33.0999 21.1519C33.7737 21.1519 34.2645 20.6392 34.2645 19.9946C34.2645 19.3428 33.7737 18.8374 33.0999 18.8374C32.4334 18.8374 31.9354 19.3428 31.9354 19.9946C31.9354 20.6392 32.4334 21.1519 33.0999 21.1519ZM33.0999 26.5864C33.7737 26.5864 34.2645 26.0811 34.2645 25.4292C34.2645 24.7773 33.7737 24.272 33.0999 24.272C32.4334 24.272 31.9354 24.7773 31.9354 25.4292C31.9354 26.0811 32.4334 26.5864 33.0999 26.5864Z" />
        <path d="M41.2521 28H43.0611V25.9712H44.482V24.4111H43.0611V17.4312H40.3878C38.9522 19.6138 37.4508 22.0454 36.0812 24.4258V25.9712H41.2521V28ZM37.839 24.4551V24.3452C38.8644 22.5508 40.0875 20.5952 41.1715 18.9399H41.2813V24.4551H37.839Z" />
        <path d="M48.5619 28H50.4516V17.4312H48.5692L45.808 19.3721V21.1519L48.4374 19.2915H48.5619V28Z" />
    </svg>

);

export default IosTopBar;
