/* eslint-disable no-loop-func, no-param-reassign, no-await-in-loop */
import { useHistory, useLocation } from 'react-router-dom';
import React, {
    memo,
    Fragment,
    useRef,
    useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormProvider } from 'react-hook-form';
import { parse } from 'query-string';
import { saveAs } from 'file-saver';
import domtoimage from 'dom-to-image';
import Resizer from 'react-image-file-resizer';
import defaultMark from '../../_apps/AppAssets/Icons/DefaultIcon.png';
import setBrandName from '../../_utilities/setBrandName';
import createTaskList from './_utilities/createTaskList';
import handleColorChange from './_utilities/handleColorChange';
import handleChange from './_utilities/handleChange';
import handleFileChange from './_utilities/handleFileChange';
import handleFileDrop from './_utilities/handleFileDrop';
import handleFileRemove from './_utilities/handleFileRemove';
import ColorInput from './ColorInput';
import FileInput from './FileInput';
import TextInput from './TextInput';
import ToggleInput from './ToggleInput';
import { SHOW_DOWNLOAD_MODAL } from '../../_store/reducer';
import setLocalStorage from '../../_utilities/setLocalStorage';

const JSZip = require('jszip');

const Form = memo((formMethods) => {
    const history = useHistory();
    const { search } = useLocation();
    const dispatch = useDispatch();
    const isDownloadModalVisible = useSelector(({ uiState }) => uiState.isDownloadModalVisible);
    const appState = useSelector(state => state.appState);
    const {
        appIconLogoMark,
        appIconLogoMarkFile,
        appIconBackColorPick,
        brandName,
        primaryColorPick,
        secondaryColorPick,
        lightLogoFile,
        lightLogoMarkFile,
        lightLogo,
        lightLogoMark,
        hasCustomAppIcon,
        instructor1,
        instructor2,
        instructor3,
        instructor1Photo,
        instructor2Photo,
        instructor3Photo,
        instructor1PhotoFile,
        instructor2PhotoFile,
        instructor3PhotoFile,
    } = appState;

    const [{
        execProgress,
        studioCount,
    }, setState] = useState({
        execProgress: 0,
        studioCount: 1,
    });
    const formRef = useRef(null);

    const handleSubmit = async () => {
        setLocalStorage(brandName, appState);
        const debugMode = 'debugMode' in parse(search);

        dispatch({
            type: SHOW_DOWNLOAD_MODAL,
            payload: { isDownloadModalVisible: true },
        });

        if (debugMode) console.log(`is Executing: ${isDownloadModalVisible}`);

        const requiredFields = [
            'primaryColor',
            'secondaryColor',
            'region',
            'brandName',
            'studio1',
            'lightLogo',
            'lightLogoMark',
            (hasCustomAppIcon ? 'appIconLogoMark' : undefined),
            (instructor1Photo ? 'instructor1' : undefined),
            (instructor2Photo ? 'instructor2' : undefined),
            (instructor3Photo ? 'instructor3' : undefined),
            (instructor1 ? 'instructor1Photo' : undefined),
            (instructor2 ? 'instructor2Photo' : undefined),
            (instructor3 ? 'instructor3Photo' : undefined),
        ].filter(f => !!f);
        if (requiredFields.map(field => appState[field]).some(f => !f)) {
            dispatch({
                type: SHOW_DOWNLOAD_MODAL,
                payload: { isDownloadModalVisible: false },
            });
            formMethods.trigger(requiredFields);
            formRef.current.blur();
            formMethods.formState.isSubmitted = true;

            formRef.current.scroll({ behavior: 'smooth', left: 0, top: 0 });
            if (debugMode) console.log('Stopping');
            return;
        }

        const namePrefix = brandName ? brandName.replace(' ', '') : 'ClientName';
        const zip = new JSZip();
        const tasksList = createTaskList({ namePrefix, zip });
        for (let i = 0; i < tasksList.length; i += 1) {
            setState(prevState => ({
                ...prevState,
                execProgress: i * 100 / tasksList.length,
            }));
            const task = tasksList[i];
            const {
                elementId, fileName, folder, height, width,
            } = task;
            const node = document.getElementById(elementId);
            if (node) {
                const options = elementId === 'AppIcon' ? undefined : { width, height };
                const blob = node && await domtoimage.toBlob(node, options);
                const resizedBlob = new Promise((resolve) => {
                    Resizer.imageFileResizer(
                        blob,
                        width,
                        height,
                        'PNG',
                        100,
                        0,
                        (uri) => { resolve(uri); },
                        'blob',
                        null,
                        null,
                    );
                });
                const res = await resizedBlob;
                const body = new FormData();
                body.append('file', res, fileName);
                const response = await fetch('/process', { method: 'POST', body });
                const processedFile = await response.text();
                folder.file(fileName, processedFile, { base64: true });
            }
        }

        const logos = zip.folder('logos');
        const fileRegex = /^data:image\/(png|jpg);base64,/;
        await logos.file(
            `${namePrefix}-Logo.png`,
            lightLogo.replace(fileRegex, ''),
            { base64: true },
        );
        await logos.file(
            `${namePrefix}-Mark.png`,
            lightLogoMark.replace(fileRegex, ''),
            { base64: true },
        );

        const content = await zip.generateAsync({ type: 'blob' });
        await saveAs(content, `${namePrefix}.zip`);
        formMethods.formState.isSubmitted = false;
        dispatch({
            type: SHOW_DOWNLOAD_MODAL,
            payload: { isDownloadModalVisible: false },
        });
    };

    const onSubmit = () => handleSubmit();
    const onFileChange = e => handleFileChange(e, { dispatch });
    const onFileDrop = e => handleFileDrop(e, { dispatch });
    const onFileRemove = e => handleFileRemove(e, { dispatch });

    const studioPlaceholders = ['Dupont Circle', 'Capitol Hill', 'Logan Circle'];
    const StudiosComponent = [1, 2, 3]
        .slice(0, studioCount)
        .map(index => (
            <Fragment key={`${index}`}>
                <div className="form-row">
                    <TextInput
                        label={`Studio ${index}`}
                        name={`studio${index}`}
                        onChange={e => handleChange(e, { dispatch })}
                        placeholder={studioPlaceholders[index - 1]}
                        required={index === 1}
                    />
                </div>
                {
                    (index === studioCount && index !== 3)
                        && (
                            <div className="form-row">
                                <button
                                    className="btn-link btn-link--small"
                                    onClick={() => setState(prevState => ({
                                        ...prevState,
                                        studioCount: prevState.studioCount + 1,
                                    }))}
                                    type="button"
                                >
                                    Add a Studio
                                </button>
                            </div>
                        )
                }
            </Fragment>
        ));

    return (
        <FormProvider {...formMethods}>
            <form className="form" ref={formRef}>
                {
                    isDownloadModalVisible
                        && (
                            <div className="progress__backdrop">
                                <div className="progress__modal">
                                    <h1>Hold tight! </h1>
                                    <p>We&apos;re grabbing your images.</p>
                                    <div className="progress-bar">
                                        <div
                                            className="progress-bar__indicator"
                                            style={{ width: `${execProgress}%` }}
                                        />
                                    </div>
                                </div>
                            </div>
                        )
                }
                <header className="form-header">
                    <div className="logo" style={{ backgroundImage: `url("${defaultMark}")` }} />
                    <h1>Mariana App Designer</h1>
                </header>
                <div className="form-wrap">

                    <section className="form-section">
                        <h2 className="section-title">
                            App Design Theme
                        </h2>
                        <div className="form-row">
                            <ColorInput
                                chromePicker={primaryColorPick}
                                label="Primary Color"
                                name="primaryColor"
                                onChangeComplete={color => handleColorChange(color, { dispatch, modifier: 'primary' })}
                                required
                            />
                            <ColorInput
                                chromePicker={secondaryColorPick}
                                label="Secondary Color"
                                name="secondaryColor"
                                onChangeComplete={color => handleColorChange(color, { dispatch, modifier: 'secondary' })}
                                required
                            />
                        </div>

                        <div className="form-row">
                            <FileInput
                                data={lightLogo}
                                description="Your full color logo in a transparent PNG format."
                                file={lightLogoFile}
                                label="Logo"
                                name="lightLogo"
                                onChange={onFileChange}
                                onDrop={onFileDrop}
                                onRemove={onFileRemove}
                                required
                            />
                        </div>

                        <div className="form-row">
                            <FileInput
                                data={lightLogoMark}
                                description="Your reduced logo—often a square ratio—as a transparent PNG."
                                file={lightLogoMarkFile}
                                label="Logo Mark"
                                name="lightLogoMark"
                                onChange={onFileChange}
                                onDrop={onFileDrop}
                                onRemove={onFileRemove}
                                required
                            />
                        </div>

                        <div className="form-row">
                            <ToggleInput
                                label="Does your studio have Pick-a-Spot layouts?"
                                description="Pick-a-Spot layouts allow your guests to reserve their favorite spot in a room, or a specific bike in a spin studio."
                                name="isPAS"
                                onChange={e => handleChange(e, { dispatch })}
                            />
                        </div>

                        <hr className="divider--light" />

                        <div className="form-row">
                            <ToggleInput
                                label="Do you need a customized App Icon?"
                                description="All app icons use the Logo Mark on a white background. If you would like to change it, please choose a color and new Logo Mark that would work."
                                name="hasCustomAppIcon"
                                onChange={e => handleChange(e, { dispatch })}
                            />
                        </div>
                        {
                            hasCustomAppIcon && (
                                <Fragment>
                                    <div className="form-row">
                                        <ColorInput
                                            chromePicker={appIconBackColorPick}
                                            label="App Icon Background Color"
                                            name="appIconBackColor"
                                            onChangeComplete={color => handleColorChange(color, { dispatch, modifier: 'appIconBack' })}
                                            required={hasCustomAppIcon}
                                        />
                                    </div>
                                    <div className="form-row">
                                        <FileInput
                                            data={appIconLogoMark}
                                            description="Your reduced logo—often a square ratio—as a transparent PNG. This logo will ONLY appear in your app icons."
                                            file={appIconLogoMarkFile}
                                            label="App Icon Logo Mark"
                                            name="appIconLogoMark"
                                            onChange={onFileChange}
                                            onDrop={onFileDrop}
                                            onRemove={onFileRemove}
                                            required={hasCustomAppIcon}
                                        />
                                    </div>
                                </Fragment>
                            )
                        }
                    </section>

                    <hr className="divider" />

                    <section className="form-section">
                        <h2 className="section-title">
                            App Store Screenshots Customization
                        </h2>
                        <div className="form-row">
                            <TextInput
                                label="Brand Name"
                                name="brandName"
                                onBlur={({ target }) => setBrandName({
                                    brandName: target.value,
                                    dispatch,
                                    history,
                                    search,
                                    state: appState,
                                })}
                                onChange={e => handleChange(e, { dispatch })}
                                placeholder="Mariana Tek"
                                required
                            />
                        </div>

                        <div className="form-row">
                            <TextInput
                                label="Region"
                                name="region"
                                onChange={e => handleChange(e, { dispatch })}
                                placeholder="Washington D.C."
                                required
                            />
                        </div>

                        {StudiosComponent}

                    </section>

                    <hr className="divider--light" />

                    <section className="form-section">
                        <h3 className="subsection-title subsection-title--optional">
                            Class Information
                        </h3>
                        <div className="form-row">
                            <TextInput
                                label="Class Name 1"
                                name="class1"
                                onChange={e => handleChange(e, { dispatch })}
                                placeholder="Arms & Abs Workout"
                            />
                        </div>

                        <div className="form-row">
                            <TextInput
                                label="Class Name 2"
                                name="class2"
                                onChange={e => handleChange(e, { dispatch })}
                                placeholder="Arms & Abs Workout"
                            />
                        </div>

                        <div className="form-row">
                            <TextInput
                                label="Class Name 3"
                                name="class3"
                                onChange={e => handleChange(e, { dispatch })}
                                placeholder="Arms & Abs Workout"
                            />
                        </div>

                    </section>

                    <hr className="divider--light" />

                    <section className="form-section">
                        <h3 className="subsection-title subsection-title--optional">
                            Instructor Information
                        </h3>
                        <p className="form-section__description">
                            If instructor names are entered, instructor photos
                            must be uploaded as well.
                        </p>
                        <div className="form-row">
                            <TextInput
                                label="Instructor Name 1"
                                name="instructor1"
                                onChange={e => handleChange(e, { dispatch })}
                                placeholder="Kara T."
                                required={!!instructor1Photo}
                            />
                        </div>

                        <div className="form-row">
                            <FileInput
                                data={instructor1Photo}
                                description="Any image file type at a minimum of 120px."
                                file={instructor1PhotoFile}
                                label="Instructor Photo 1"
                                name="instructor1Photo"
                                onChange={onFileChange}
                                onDrop={onFileDrop}
                                onRemove={onFileRemove}
                                required={!!instructor1}
                            />
                        </div>
                    </section>
                    <hr className="divider--light" />
                    <section className="form-section">
                        <div className="form-row">
                            <TextInput
                                label="Instructor Name 2"
                                name="instructor2"
                                onChange={e => handleChange(e, { dispatch })}
                                placeholder="John S."
                                required={!!instructor2Photo}
                            />
                        </div>

                        <div className="form-row">
                            <FileInput
                                data={instructor2Photo}
                                description="Any image file type at a minimum of 120px."
                                file={instructor2PhotoFile}
                                label="Instructor Photo 2"
                                name="instructor2Photo"
                                onChange={onFileChange}
                                onDrop={onFileDrop}
                                onRemove={onFileRemove}
                                required={!!instructor2}
                            />
                        </div>
                    </section>
                    <hr className="divider--light" />
                    <section className="form-section">
                        <div className="form-row">
                            <TextInput
                                label="Instructor Name 3"
                                name="instructor3"
                                onChange={e => handleChange(e, { dispatch })}
                                placeholder="Amanda R."
                                required={!!instructor3Photo}
                            />
                        </div>
                        <div className="form-row">
                            <FileInput
                                data={instructor3Photo}
                                description="Any image file type at a minimum of 120px."
                                file={instructor3PhotoFile}
                                label="Instructor Photo 3"
                                name="instructor3Photo"
                                onChange={onFileChange}
                                onDrop={onFileDrop}
                                onRemove={onFileRemove}
                                required={!!instructor3}
                            />
                        </div>
                        <hr className="divider--light" />
                        <div className="form-row">
                            <button
                                className="btn-submit"
                                disabled={isDownloadModalVisible}
                                onClick={onSubmit}
                                type="button"
                            >
                                Download Assets
                            </button>
                        </div>

                    </section>
                </div>
            </form>
        </FormProvider>
    );
});

Form.displayName = 'Form';

export default Form;
