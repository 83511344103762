import React from 'react';
import { createStore } from 'redux';
import { Provider } from 'react-redux';
import reducer from './reducer';

const store = createStore(reducer);

const Store = ({ children }) => (
    <Provider store={store}>
        {children}
    </Provider>
);
export default Store;
