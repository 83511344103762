import React, { memo } from 'react';
import PaS from '../../views/PaS';
import defaultPixel3 from '../../../AppAssets/Phones/android-upfront.png';

const Android4 = memo(() => (
    <div>
        <div className="screenshot screenshot-android screenshot-4">
            <div className="phone-outer">
                <img className="phoneMock" src={defaultPixel3} alt="Pixel 3" />
                <PaS device="Android" />
            </div>
        </div>
    </div>
));

Android4.displayName = 'Android4';

export default Android4;
