import React from 'react';

const IphoneXCP = () => (
    <svg className="visually-hidden" height="0" viewBox="0 0 0 0" fill="none" xmlns="http://www.w3.org/2000/svg">
        <clipPath id="phoneClip">
            <path fillRule="evenodd" clipRule="evenodd" d="M78.3145 0.231445C81.0759 0.231445 83.3145 2.47002 83.3145 5.23145V8.23145C83.3145 17.663 92.2584 30.2314 107.126 30.2314H268.503C283.371 30.2314 292.314 17.663 292.314 8.23145V5.23145C292.314 2.47002 294.553 0.231445 297.314 0.231445H336.314C357.854 0.231445 375.314 15.8798 375.314 39.2314V773.231C375.314 796.583 357.854 812.231 336.314 812.231H39.3145C17.7753 812.231 0.314453 796.583 0.314453 773.231V39.2314C0.314453 15.8798 17.7753 0.231445 39.3145 0.231445H78.3145Z" fill="#C4C4C4" />
        </clipPath>
    </svg>
);

export default IphoneXCP;
