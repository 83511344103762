import React, { memo } from 'react';
import PaS from '../../views/PaS';
import defaultPixel3 from '../../../AppAssets/Phones/android-upfront.png';

const Android5 = memo(() => (
    <div>
        <div className="screenshot screenshot-android screenshot-5">
            <h2>Seamlessly book, change and reserve your favorite spots!</h2>
            <div className="phone-outer">
                <img className="phoneMock" src={defaultPixel3} alt="Pixel 3" />
                <PaS device="Android" />
            </div>
        </div>
    </div>
));

Android5.displayName = 'Android5';

export default Android5;
