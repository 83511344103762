import React, { memo } from 'react';
import PaS from '../../views/PaS';
import iPhone8 from '../../../AppAssets/Phones/iphone8_upfront.png';

const Iphone84 = memo(() => (
    <div>
        <div className="screenshot screenshot-iphone8 screenshot-4">
            <div className="phone-outer">
                <img className="phoneMock" src={iPhone8} alt="iphone8" />
                <PaS />
            </div>
        </div>
    </div>
));

Iphone84.displayName = 'Iphone84';

export default Iphone84;
