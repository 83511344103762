import React from 'react';

const FormField = ({
    children,
    className,
    description,
    label,
    isValid = true,
}) => (
    <div className={className || 'form-field'}>
        <label className="form-field">
            <div className="form-field__label">
                {label}
            </div>
            {
                description && (
                    <div className="form-field__description">
                        {description}
                    </div>
                )
            }
            {children}
            {
                !isValid
                    && <div className="form-field__error">{`${label} is required`}</div>
            }
        </label>
    </div>
);

export default FormField;
