/* eslint-disable no-case-declarations */
import appState from './initialAppState';
import uiState from './initialUIState';

const initialState = {
    appState,
    uiState,
};

const CHANGE_COLOR = 'CHANGE_COLOR';
const CHANGE_FILE = 'CHANGE_FILE';
const INITIALIZE = 'INITIALIZE';
const REMOVE_FILE = 'REMOVE_FILE';
const UPDATE_APP = 'UPDATE_APP';
const SET_AUTO_SAVE = 'SET_AUTO_SAVE';
const SET_SLIDE = 'SET_SLIDE';
const SHOW_DOWNLOAD_MODAL = 'SHOW_DOWNLOAD_MODAL';
const SHOW_FORM = 'SHOW_FORM';
const SHOW_PREVIEW = 'SHOW_PREVIEW';

const reducer = (state = initialState, action) => {
    const { payload, type } = action;

    switch (type) {
        case CHANGE_COLOR:
            return {
                ...state,
                appState: {
                    ...state.appState,
                    [`${payload.modifier}Color`]: payload.hex,
                    [`${payload.modifier}ColorPick`]: payload.hex,
                },
            };
        case CHANGE_FILE:
            return {
                ...state,
                appState: {
                    ...state.appState,
                    [`${payload.name}File`]: payload.file,
                    [payload.name]: payload.result,
                },
            };
        case INITIALIZE:
            return {
                appState: {
                    ...payload,
                    appIconBackColorPick: payload.appIconBackColor
                        || state.appState.appIconBackColorPick,
                    primaryColorPick: payload.primaryColor
                        || state.appState.primaryColorPick,
                    secondaryColorPick: payload.secondaryColor
                        || state.appState.secondaryColorPick,
                },
                uiState: {
                    ...state.uiState,
                    autoSave: payload.autoSave,
                    initialized: true,
                },
            };
        case REMOVE_FILE:
            return {
                ...state,
                appState: {
                    ...state.appState,
                    [`${payload.name}File`]: null,
                    [payload.name]: null,
                },
            };
        case SET_AUTO_SAVE:
            clearInterval(state.uiState.autoSave);

            return {
                ...state,
                uiState: {
                    ...state.uiState,
                    autoSave: payload.autoSave,
                },
            };
        case SET_SLIDE:
            return {
                ...state,
                uiState: {
                    ...state.uiState,
                    slideIndex: payload.slideIndex,
                },
            };
        case SHOW_DOWNLOAD_MODAL:
            return {
                ...state,
                uiState: {
                    ...state.uiState,
                    isDownloadModalVisible: payload.isDownloadModalVisible,
                    isValid: payload.isValid,
                },
            };
        case SHOW_FORM:
            return {
                ...state,
                uiState: {
                    ...state.uiState,
                    isFormVisible: payload.isFormVisible,
                },
            };
        case SHOW_PREVIEW:
            return {
                ...state,
                uiState: {
                    ...state.uiState,
                    isPreviewVisible: payload.isPreviewVisible,
                },
            };
        case UPDATE_APP:
            const colorValue = payload.isColor
                && (payload.value[0] !== '#')
                && `#${payload.value}`;

            const nonColorValue = payload.isCheckbox
                ? !state.appState[payload.name]
                : payload.value;

            const colorPickValue = colorValue
                ? { [`${payload.name}Pick`]: colorValue }
                : {};

            return {
                ...state,
                appState: {
                    ...state.appState,
                    ...colorPickValue,
                    [payload.name]: colorValue || nonColorValue,
                },
            };
        default:
            return state;
    }
};

export {
    CHANGE_COLOR,
    CHANGE_FILE,
    INITIALIZE,
    initialState,
    REMOVE_FILE,
    SET_AUTO_SAVE,
    SET_SLIDE,
    SHOW_DOWNLOAD_MODAL,
    SHOW_FORM,
    SHOW_PREVIEW,
    UPDATE_APP,
};

export default reducer;
