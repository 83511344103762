import React, { memo } from 'react';
import Achievements from '../../views/Achievements';
import iPhone8 from '../../../AppAssets/Phones/iphone8_upfront.png';

const Iphone86 = memo(() => (
    <div>
        <div className="screenshot screenshot-iphone8 screenshot-6">
            <h2>Celebrate your workout achievements and share them with your friends!</h2>
            <div className="phone-outer">
                <img className="phoneMock" src={iPhone8} alt="iphone8" />
                <Achievements />
            </div>
        </div>
    </div>
));

Iphone86.displayName = 'Iphone86';

export default Iphone86;
