import React, { useEffect, useState } from 'react';
import { useController, useFormContext } from 'react-hook-form';
import FormField from './FormField';
import trashCan from './trashcan.png';

const preventDefault = (e) => {
    e.preventDefault();
    e.stopPropagation();
};

const FileInput = ({
    data,
    description,
    file,
    label,
    name,
    onChange,
    onDrop,
    onRemove,
    required,
}) => {
    const { control, formState } = useFormContext();
    const [isValid, setIsValid] = useState(true);

    const {
        field: {
            onChange: onc,
            onBlur,
            ref,
        },
        meta: { isDirty, invalid },
    } = useController({
        name,
        control,
        rules: { required },
        defaultValue: '',
    });

    useEffect(() => {
        if (formState.isSubmitted) setIsValid(!required || !invalid);
    }, [formState]);

    useEffect(() => {
        const timer = setTimeout(() => {
            setIsValid(!(isDirty && !data));
        }, 2000);

        return clearTimeout(timer);
    }, [data]);

    return (
        <FormField
            description={description}
            isValid={isValid}
            label={label}
            name={name}
        >
            <div
                className={`form-image-drop form-image-drop${isValid ? '' : '--error'}`}
                data-name={name}
                onDrop={(e) => {
                    if (onDrop) onDrop(e);
                    onc(e.target.value);
                }}
                onDragOver={preventDefault}
                onDragEnter={preventDefault}
                onDragLeave={preventDefault}
            >

                {
                    data
                        && (
                            <div
                                className="form-image-drop__preview"
                                style={{ backgroundImage: `url("${data}")` }}
                            />
                        )
                }

                <div className="form-image-drop__label">
                    { file ? (
                        <div>
                            <p>{file.name}</p>
                            <p>{parseFloat((file.size / (1024 ** Math.floor(Math.log(file.size) / Math.log(1024)))).toFixed(2)) + ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'][Math.floor(Math.log(file.size) / Math.log(1024))] || ''}</p>
                        </div>
                    ) : (
                        <div>
                            <span className="highlight">Choose a file</span>
                            &nbsp;or drag it here.
                        </div>
                    ) }
                    {' '}
                </div>
                <input
                    accept=".png"
                    className="form-image-drop__input"
                    name={name}
                    onBlur={onBlur}
                    onInput={(e) => {
                        if (onChange) onChange(e);
                        onc(e.target.value);
                    }}
                    ref={ref}
                    required={required}
                    type="file"
                />
                {
                    file
                        && (
                            <button
                                className="btn-remove"
                                style={{ backgroundImage: `url("${trashCan}")` }}
                                onClick={(e) => {
                                    if (onRemove) onRemove(e);
                                }}
                                name={name}
                                type="button"
                            >
                                Remove
                            </button>
                        )
                }
            </div>
        </FormField>
    );
};

export default FileInput;
