import React, { memo } from 'react';
import { useSelector } from 'react-redux';
import getForegroundColor from '../../_utilities/getForegroundColor';
import { designTheme } from '../../_store/initialAppState';

const ButtonNav = memo(({
    direction,
    onClick,
}) => {
    const secondaryColor = useSelector(({ appState }) => appState.secondaryColor);
    const arrowColor = getForegroundColor(
        secondaryColor || designTheme.secondaryColorPick,
    );
    const arrow = `<svg viewBox="0 0 268.832 268.832" fill="${arrowColor}" xmlns="http://www.w3.org/2000/svg"><path d="M265.171 125.577l-80-80c-4.881-4.881-12.797-4.881-17.678 0-4.882 4.882-4.882 12.796 0 17.678l58.661 58.661H12.5c-6.903 0-12.5 5.597-12.5 12.5 0 6.902 5.597 12.5 12.5 12.5h213.654l-58.659 58.661c-4.882 4.882-4.882 12.796 0 17.678 2.44 2.439 5.64 3.661 8.839 3.661s6.398-1.222 8.839-3.661l79.998-80c4.882-4.882 4.882-12.796 0-17.678z" transform="rotate(${direction === 'prev' ? 180 : 0}, 134.416, 134.416)"/></svg>`;

    return (
        <button
            className={`btn-nav ${direction}`}
            onClick={onClick}
            type="button"
            style={{
                backgroundImage: `url(data:image/svg+xml;base64,${Buffer.from(arrow).toString('base64')})`,
            }}
        >
            <span className="visually-hidden">
                {direction === 'prev' ? 'Previous' : 'Next'}
            </span>
        </button>
    );
});

ButtonNav.displayName = 'ButtonNav';

export default ButtonNav;
