import React, { memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SHOW_PREVIEW } from '../_store/reducer';
import Preview from './preview/Preview';
import Slideshow from './slideshow/Slideshow';
import greenArrow from './greenarrow.png';
import greenArrowBack from './greenarrowback.png';

const RightContainer = memo(() => {
    const dispatch = useDispatch();
    const isPreviewVisible = useSelector(({ uiState }) => uiState.isPreviewVisible);

    const setIsPreviewVisible = () => {
        dispatch({
            type: SHOW_PREVIEW,
            payload: { isPreviewVisible: !isPreviewVisible },
        });
    };

    return (
        <section className="content content-right">
            <nav className="site-nav">
                {
                    isPreviewVisible ? (
                        <button
                            type="button"
                            className="btn-link"
                            onClick={setIsPreviewVisible}
                        >
                            <img src={greenArrowBack} alt="back arrow" />
                            Back
                        </button>
                    ) : (
                        <button
                            type="button"
                            className="btn-link"
                            onClick={setIsPreviewVisible}
                        >
                            View All Screenshots
                            <img src={greenArrow} alt="forward arrow" />
                        </button>
                    )
                }
            </nav>
            <Preview />
            { !isPreviewVisible && <Slideshow /> }
        </section>
    );
});

RightContainer.displayName = 'RightContainer';

export default RightContainer;
