import React from 'react';

const DownArrow = () => (

    <svg width="18" height="11" viewBox="0 0 18 11" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M9.15234 10.3203C9.49414 10.3105 9.80664 10.1836 10.0605 9.91016L17.4824 2.3125C17.6973 2.09766 17.8145 1.82422 17.8145 1.50195C17.8145 0.857422 17.3066 0.339844 16.6621 0.339844C16.3496 0.339844 16.0469 0.466797 15.8223 0.691406L9.16211 7.53711L2.48242 0.691406C2.25781 0.476562 1.96484 0.339844 1.64258 0.339844C0.998047 0.339844 0.490234 0.857422 0.490234 1.50195C0.490234 1.82422 0.607422 2.09766 0.822266 2.3125L8.25391 9.91016C8.51758 10.1836 8.81055 10.3203 9.15234 10.3203Z" />
    </svg>

);
export default DownArrow;
