import React, { memo } from 'react';
import { useSelector } from 'react-redux';
import PreviewRowIPhoneX from './PreviewRowIPhoneX';
import PreviewRowIPhone8 from './PreviewRowIPhone8';
import PreviewRowAndroid from './PreviewRowAndroid';
import PreviewRowMiscellany from './PreviewRowMiscellany';

const Preview = memo(() => {
    const isPreviewVisible = useSelector(({ uiState }) => uiState.isPreviewVisible);

    return (
        <div className={`preview preview${isPreviewVisible ? '' : '--hidden'}`}>
            <div className="preview__wrapper">
                <PreviewRowIPhoneX />
                <PreviewRowIPhone8 />
                <PreviewRowAndroid />
                <PreviewRowMiscellany />
            </div>

        </div>
    );
});

Preview.displayName = 'Preview';

export default Preview;
