import React from 'react';

const IosFilter = () => (
    <svg width="32" height="27" viewBox="0 0 32 27" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M8.52783 4.95334C8.52783 4.47469 8.91585 4.08667 9.3945 4.08667H26.5678C27.0465 4.08667 27.4345 4.47469 27.4345 4.95334C27.4345 5.43198 27.0465 5.82 26.5678 5.82H9.3945C8.91585 5.82 8.52783 5.43198 8.52783 4.95334Z" />
        <path fillRule="evenodd" clipRule="evenodd" d="M0.155762 4.95334C0.155762 4.47469 0.543782 4.08667 1.02243 4.08667H2.64643C3.12508 4.08667 3.51309 4.47469 3.51309 4.95334C3.51309 5.43198 3.12508 5.82 2.64643 5.82H1.02243C0.543782 5.82 0.155762 5.43198 0.155762 4.95334Z" />
        <path fillRule="evenodd" clipRule="evenodd" d="M23.9932 13.2785C23.9932 12.7999 24.3812 12.4119 24.8598 12.4119H26.5678C27.0465 12.4119 27.4345 12.7999 27.4345 13.2785C27.4345 13.7572 27.0465 14.1452 26.5678 14.1452H24.8598C24.3812 14.1452 23.9932 13.7572 23.9932 13.2785Z" />
        <path fillRule="evenodd" clipRule="evenodd" d="M0.155762 13.2785C0.155762 12.7999 0.543782 12.4119 1.02243 12.4119H18.2424C18.7211 12.4119 19.1091 12.7999 19.1091 13.2785C19.1091 13.7572 18.7211 14.1452 18.2424 14.1452H1.02243C0.543782 14.1452 0.155762 13.7572 0.155762 13.2785Z" />
        <path fillRule="evenodd" clipRule="evenodd" d="M14.0439 22.8266C14.0439 22.348 14.432 21.96 14.9106 21.96H26.5679C27.0466 21.96 27.4346 22.348 27.4346 22.8266C27.4346 23.3053 27.0466 23.6933 26.5679 23.6933H14.9106C14.432 23.6933 14.0439 23.3053 14.0439 22.8266Z" />
        <path fillRule="evenodd" clipRule="evenodd" d="M0.155762 22.8266C0.155762 22.348 0.543782 21.96 1.02243 21.96H8.61043C9.08907 21.96 9.47709 22.348 9.47709 22.8266C9.47709 23.3053 9.08907 23.6933 8.61043 23.6933H1.02243C0.543782 23.6933 0.155762 23.3053 0.155762 22.8266Z" />
        <path fillRule="evenodd" clipRule="evenodd" d="M8.17285 23.0132C8.17285 21.0242 9.78522 19.4119 11.7742 19.4119C13.7631 19.4119 15.3755 21.0242 15.3755 23.0132C15.3755 25.0022 13.7631 26.6145 11.7742 26.6145C9.78522 26.6145 8.17285 25.0022 8.17285 23.0132ZM11.7742 21.1452C10.7425 21.1452 9.90618 21.9815 9.90618 23.0132C9.90618 24.0449 10.7425 24.8812 11.7742 24.8812C12.8059 24.8812 13.6422 24.0449 13.6422 23.0132C13.6422 21.9815 12.8059 21.1452 11.7742 21.1452Z" />
        <path fillRule="evenodd" clipRule="evenodd" d="M17.9453 13.2507C17.9453 11.2618 19.5577 9.64941 21.5466 9.64941C23.5356 9.64941 25.148 11.2618 25.148 13.2507C25.148 15.2397 23.5356 16.8521 21.5466 16.8521C19.5577 16.8521 17.9453 15.2397 17.9453 13.2507ZM21.5466 11.3827C20.515 11.3827 19.6786 12.2191 19.6786 13.2507C19.6786 14.2824 20.515 15.1187 21.5466 15.1187C22.5783 15.1187 23.4146 14.2824 23.4146 13.2507C23.4146 12.2191 22.5783 11.3827 21.5466 11.3827Z" />
        <path fillRule="evenodd" clipRule="evenodd" d="M2.52637 4.92531C2.52637 2.93635 4.13874 1.32397 6.1277 1.32397C8.11666 1.32397 9.72903 2.93635 9.72903 4.92531C9.72903 6.91427 8.11666 8.52664 6.1277 8.52664C4.13874 8.52664 2.52637 6.91427 2.52637 4.92531ZM6.1277 3.05731C5.09603 3.05731 4.2597 3.89364 4.2597 4.92531C4.2597 5.95698 5.09603 6.79331 6.1277 6.79331C7.15937 6.79331 7.9957 5.95698 7.9957 4.92531C7.9957 3.89364 7.15937 3.05731 6.1277 3.05731Z" />
    </svg>
);

export default IosFilter;
