import React, { memo } from 'react';
import { useSelector } from 'react-redux';
import Home from '../../views/Home';
import defaultMark from '../../../AppAssets/Icons/DefaultIcon.png';
import defaultPixel3Angle from '../../../AppAssets/Phones/android_angle.png';

const Android1 = memo(() => {
    const lightLogoMark = useSelector(({ appState }) => appState.lightLogoMark);
    const brandName = useSelector(({ appState }) => appState.brandName);

    return (
        <div>
            <div className="screenshot screenshot-android screenshot-1">
                <img
                    className="clientMark"
                    src={lightLogoMark || defaultMark}
                    alt="client mark"
                />
                <h1>
                    Welcome to
                    {' '}
                    <span className="brandName">{brandName || 'Mariana Tek'}</span>
                </h1>
                <div className="colorAngle" />
                <div className="phone-outer">
                    <img className="phoneMock" src={defaultPixel3Angle} alt="Pixel 3" />
                    <Home device="Android" />
                </div>
            </div>

        </div>
    );
});

Android1.displayName = 'Android1';

export default Android1;
