import React, { memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SET_SLIDE } from '../../_store/reducer';
import ButtonNav from './ButtonNav';
import ButtonDot from './ButtonDot';

const SlideshowNav = memo(() => {
    const isPAS = useSelector(({ appState }) => appState.isPAS);
    const slideIndex = useSelector(({ uiState }) => uiState.slideIndex);
    const dispatch = useDispatch();

    const setSlide = (value) => {
        const lastIndex = isPAS ? 3 : 2;

        dispatch({
            type: SET_SLIDE,
            payload: {
                slideIndex: (value < 1 && lastIndex)
                    || (value > lastIndex && 1)
                    || value,
            },
        });
    };

    return (
        <nav className="slider-nav">
            <ButtonNav
                direction="prev"
                onClick={() => setSlide(slideIndex - 1)}
            />
            <div>
                <ButtonDot
                    isActive={slideIndex === 1}
                    onClick={() => setSlide(1)}
                />
                <ButtonDot
                    isActive={slideIndex === 2}
                    onClick={() => setSlide(2)}
                />
                {
                    isPAS && (
                        <ButtonDot
                            isActive={slideIndex === 3}
                            onClick={() => setSlide(3)}
                        />
                    )
                }
            </div>
            <ButtonNav
                direction="next"
                onClick={() => setSlide(slideIndex + 1)}
            />
        </nav>
    );
});

SlideshowNav.displayName = 'SlideshowNav';

export default SlideshowNav;
