import React, { memo } from 'react';
import { useSelector } from 'react-redux';
import CreateAccount from '../../views/CreateAccount';
import Welcome from '../../views/Welcome';
import defaultLogo from '../../../AppAssets/Icons/defaultLogo.png';
import defaultPixel3 from '../../../AppAssets/Phones/android-upfront.png';
import androidBottom from '../../../AppAssets/Icons/androidBottom.png';

const AndroidPlay = memo(() => {
    const lightLogo = useSelector(({ appState }) => appState.lightLogo);

    return (
        <div>
            <div id="AppPlayStore" className="screenshot screenshot-android">
                <div className="image-outer">
                    <img className="clientLogo" src={lightLogo || defaultLogo} alt="client logo" />
                </div>
                <div className="phone-outer-2 phone-outer">
                    <img className="phoneMock" src={defaultPixel3} alt="Pixel 3" />
                    {/* <!--               create account view --> */}
                    <div className="view view-createAccount createAccount">
                        <CreateAccount device="Android" />
                        <img src={androidBottom} className="android-buttons" alt="" />
                    </div>
                </div>

                <div className="phone-outer-1 phone-outer">
                    <img className="phoneMock" src={defaultPixel3} alt="Pixel 3" />
                    <Welcome device="Android" />
                </div>
                {/* <!--             background --> */}
                <div className="colorAngle" />
            </div>

        </div>
    );
});

AndroidPlay.displayName = 'AndroidPlay';

export default AndroidPlay;
