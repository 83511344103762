const setLocalStorage = (brandName, state) => {
    const {
        appIconBackColor,
        appIconLogoMark,
        appIconLogoMarkFile,
        class1,
        class2,
        class3,
        instructor1,
        instructor1PhotoFile,
        instructor2,
        instructor2PhotoFile,
        instructor3,
        instructor3Photo,
        isPAS,
        lightLogo,
        lightLogoFile,
        lightLogoMark,
        lightLogoMarkFile,
        primaryColor,
        region,
        secondaryColor,
        studio1,
        studio2,
        studio3,
    } = state;

    if (!brandName) return;

    const savedState = {
        appIconBackColor,
        appIconLogoMark,
        appIconLogoMarkFile,
        brandName,
        class1,
        class2,
        class3,
        instructor1,
        instructor1PhotoFile,
        instructor2,
        instructor2PhotoFile,
        instructor3,
        instructor3Photo,
        isPAS,
        lightLogo,
        lightLogoFile,
        lightLogoMark,
        lightLogoMarkFile,
        primaryColor,
        region,
        secondaryColor,
        studio1,
        studio2,
        studio3,
    };

    const set = () => {
        try {
            window.localStorage.setItem(
                brandName,
                JSON.stringify(savedState),
            );
        } catch (err) {
            console.error(err);
            window.localStorage.clear();
            set();
        }
    };
    set();
};

export default setLocalStorage;
