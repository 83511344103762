import React, { memo } from 'react';
import { useSelector } from 'react-redux';
import Home from '../../views/Home';
import defaultMark from '../../../AppAssets/Icons/DefaultIcon.png';
import iPhoneXAngle from '../../../AppAssets/Phones/iphoneX_angle.png';

const IphoneX1 = memo(() => {
    const lightLogoMark = useSelector(({ appState }) => appState.lightLogoMark);
    const brandName = useSelector(({ appState }) => appState.brandName);

    return (
        <div>
            <div id="tempiphonex" className="screenshot screenshot-1">

                <img className="clientMark" src={lightLogoMark || defaultMark} alt="client mark" />
                <h1>
                    Welcome to
                    {' '}
                    <span className="brandName">{brandName || 'Mariana Tek'}</span>
                </h1>

                <div className="colorAngle" />
                <div className="phone-outer">
                    <img className="phoneMock" src={iPhoneXAngle} alt="iPhone X" />
                    <Home />
                </div>
            </div>
        </div>
    );
});

IphoneX1.displayName = 'IphoneX1';

export default IphoneX1;
