const designTheme = {
    appIconBackColor: '#ffffff',
    appIconBackColorPick: '#ffffff',
    appIconLogoMark: undefined,
    appIconLogoMarkFile: undefined,
    hasCustomAppIcon: false,
    isPAS: false,
    lightLogo: undefined,
    lightLogoFile: undefined,
    lightLogoMark: undefined,
    lightLogoMarkFile: undefined,
    primaryColor: '',
    primaryColorPick: '#000000',
    secondaryColor: '',
    secondaryColorPick: '#7833FF',
};

const screeshotCustomization = {
    brandName: '',
    class1: '',
    class2: '',
    class3: '',
    instructor1: '',
    instructor1Photo: undefined,
    instructor1PhotoFile: undefined,
    instructor2: '',
    instructor2Photo: undefined,
    instructor2PhotoFile: undefined,
    instructor3: '',
    instructor3Photo: undefined,
    instructor3PhotoFile: undefined,
    region: '',
    studio1: '',
    studio2: '',
    studio3: '',
};

const initialAppState = {
    ...designTheme,
    ...screeshotCustomization,
};

export { designTheme };

export default initialAppState;
