import React, {
    memo,
    useLayoutEffect,
} from 'react';
import { withRouter, useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { parse } from 'query-string';
import merge from 'lodash.merge';
import './_styles/App.scss';
import { INITIALIZE } from './_store/reducer';
import IphoneXCP from './_apps/mobile/layouts/iPhoneX/IphoneXCP';
import setCSSColorProperties from './_utilities/setCSSColorProperties';
import resetAutoSave from './_utilities/resetAutoSave';
import setQueryParams from './_utilities/setQueryParams';
import LeftContainer from './_ui/LeftContainer';
import RightContainer from './_ui/RightContainer';

const App = memo(() => {
    const { pathname, search } = useLocation();
    const history = useHistory();
    const dispatch = useDispatch();
    const appState = useSelector(state => state.appState);
    const isFormVisible = useSelector(({ uiState }) => uiState.isFormVisible);
    const { autoSave } = appState;

    useLayoutEffect(() => {
        const instanceName = pathname && window.decodeURI(pathname.replace('/', ''));

        let savedState = parse(search);

        try {
            savedState = merge(
                JSON.parse(window.localStorage.getItem(instanceName)),
                parse(search),
            );
        } catch (err) {
            console.error(err);
        }

        const parsedState = savedState && Object.keys(savedState)
            .reduce((acc, curr) => {
                let value;

                if (savedState[curr] === 'true') {
                    value = true;
                } else if (savedState[curr] === 'false') {
                    value = false;
                } else {
                    value = savedState[curr];
                }

                return { ...acc, [curr]: value };
            }, {});

        const nextState = merge(appState, parsedState);

        dispatch({
            type: INITIALIZE,
            payload: { ...nextState, pathname },
        });

        const { brandName, primaryColor, secondaryColor } = nextState;

        if (primaryColor) {
            setCSSColorProperties({ hex: primaryColor, modifier: 'primary' });
        }

        if (secondaryColor) {
            setCSSColorProperties({ hex: secondaryColor, modifier: 'secondary' });
        }

        if (brandName) {
            setQueryParams(history, nextState);
            resetAutoSave({
                brandName,
                dispatch,
                search,
                state: nextState,
            });
        }

        return () => clearInterval(autoSave);
    }, []);

    return (
        <main className={isFormVisible ? 'openNav' : 'closedNav'}>
            <IphoneXCP />
            <LeftContainer />
            <RightContainer />
        </main>
    );
});

App.displayName = 'App';

export default withRouter(App);
