import React, { memo } from 'react';
import Schedule from '../../views/Schedule';
import iPhoneXFront from '../../../AppAssets/Phones/iphoneX_upfront.png';

const IphoneX3 = memo(() => (
    <div>
        <div className="screenshot screenshot-3">
            <h2>Explore the schedule and book classes!</h2>
            <div className="phone-outer">
                <img className="phoneMock" src={iPhoneXFront} alt="iPhone X" />
                <Schedule />
            </div>
        </div>

    </div>
));

IphoneX3.displayName = 'IphoneX3';

export default IphoneX3;
