import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import Store from './_store/Store';

import App from './App';

ReactDOM.render(
    <Router>
        <Route>
            <Store>
                <App />
            </Store>
        </Route>
    </Router>,
    document.getElementById('root'),
);
