import { getContrast } from 'polished';

const getForegroundColor = (backgroundColor) => {
    const acceptableLenghts = [4, 7];

    if (!acceptableLenghts.includes(backgroundColor.length)) return '';

    const blackColor = '#000000';
    const whiteColor = '#ffffff';

    const blackContrastRatio = getContrast(backgroundColor, blackColor);
    const whiteContrastRatio = getContrast(backgroundColor, whiteColor);

    return blackContrastRatio > whiteContrastRatio ? blackColor : whiteColor;
};

export default getForegroundColor;
