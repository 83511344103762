import React, { memo } from 'react';
import Achievements from '../../views/Achievements';
import defaultPixel3 from '../../../AppAssets/Phones/android-upfront.png';

const Android6 = memo(() => (
    <div>
        <div className="screenshot screenshot-android screenshot-6">
            <h2>Celebrate your workout achievements and share them with your friends!</h2>
            <div className="phone-outer">
                <img className="phoneMock" src={defaultPixel3} alt="Pixel 3" />
                <Achievements />
            </div>
        </div>
    </div>
));

Android6.displayName = 'Android6';

export default Android6;
