import React, { memo } from 'react';
import { useSelector } from 'react-redux';
import Home from '../../views/Home';
import defaultMark from '../../../AppAssets/Icons/DefaultIcon.png';
import iPhone8Angle from '../../../AppAssets/Phones/iphone8_angle.png';

const Iphone81 = memo(() => {
    const lightLogoMark = useSelector(({ appState }) => appState.lightLogoMark);
    const brandName = useSelector(({ appState }) => appState.brandName);

    return (
        <div>
            <div className="screenshot screenshot-iphone8 screenshot-1">
                <img className="clientMark" src={lightLogoMark || defaultMark} alt="client mark" />
                <h1>
                    Welcome to
                    {' '}
                    <span className="brandName">{brandName || 'Mariana Tek'}</span>
                </h1>
                <div className="colorAngle" />
                <div className="phone-outer">
                    <img className="phoneMock" src={iPhone8Angle} alt="iphone8" />
                    <Home />
                </div>
            </div>

        </div>
    );
});

Iphone81.displayName = 'Iphone81';

export default Iphone81;
