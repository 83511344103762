import React, { memo } from 'react';
import Home from '../../views/Home';
import iPhone8Angle from '../../../AppAssets/Phones/iphone8_angle.png';

const Iphone82 = memo(() => (
    <div>
        <div className="screenshot screenshot-iphone8 screenshot-2">
            <h2>Find everything you need on your personalized homepage!</h2>
            <div className="colorAngle" />
            <div className="phone-outer">
                <img className="phoneMock" src={iPhone8Angle} alt="iphone8" />
                <Home />
            </div>
        </div>
    </div>
));

Iphone82.displayName = 'Iphone82';

export default Iphone82;
