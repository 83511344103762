import { parse } from 'query-string';
import getForegroundColor from './getForegroundColor';

const setCSSColorProperties = ({ hex, modifier }) => {
    const { location: { search } } = window;
    const debugMode = 'debugMode' in parse(search);

    const root = document.documentElement;

    if (debugMode) console.log(`Set ${modifier} color`);

    root.style.setProperty(`--${modifier}Color`, hex);

    root.style.setProperty(`--${modifier}Foreground`, getForegroundColor(hex));
};

export default setCSSColorProperties;
