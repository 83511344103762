import React, { Fragment, memo } from 'react';
import { useSelector } from 'react-redux';
import Android1 from '../../_apps/mobile/layouts/Android/Android1';
import Android2 from '../../_apps/mobile/layouts/Android/Android2';
import Android3 from '../../_apps/mobile/layouts/Android/Android3';
import Android4 from '../../_apps/mobile/layouts/Android/Android4';
import Android5 from '../../_apps/mobile/layouts/Android/Android5';
import Android6 from '../../_apps/mobile/layouts/Android/Android6';

const PreviewRowAndroid = memo(() => {
    const isPAS = useSelector(({ appState }) => appState.isPAS);

    return (
        <div className="preview__row">
            <div id="AndroidScreenShot1" className="export-android">
                <Android1 />
            </div>
            <div id="AndroidScreenShot2" className="export-android">
                <Android2 />
            </div>
            <div id="AndroidScreenShot3" className="export-android">
                <Android3 />
            </div>

            {
                isPAS && (
                    <Fragment>
                        <div id="AndroidScreenShot4" className="export-android">
                            <Android4 />
                        </div>
                        <div id="AndroidScreenShot5" className="export-android">
                            <Android5 />
                        </div>
                    </Fragment>
                )
            }

            <div id="AndroidScreenShot6" className="export-android">
                <Android6 />
            </div>
        </div>
    );
});

PreviewRowAndroid.displayName = 'PreviewRowAndroid';

export default PreviewRowAndroid;
