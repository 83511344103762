import React, { Fragment, memo } from 'react';
import { useSelector } from 'react-redux';
import Iphone81 from '../../_apps/mobile/layouts/iPhone8/Iphone81';
import Iphone82 from '../../_apps/mobile/layouts/iPhone8/Iphone82';
import Iphone83 from '../../_apps/mobile/layouts/iPhone8/Iphone83';
import Iphone84 from '../../_apps/mobile/layouts/iPhone8/Iphone84';
import Iphone85 from '../../_apps/mobile/layouts/iPhone8/Iphone85';
import Iphone86 from '../../_apps/mobile/layouts/iPhone8/Iphone86';

const PreviewRowIPhone8 = memo(() => {
    const isPAS = useSelector(({ appState }) => appState.isPAS);

    return (
        <div className="preview__row">
            <div id="iPhone8ScreenShot1" className="export-iphone8">
                <Iphone81 />
            </div>
            <div id="iPhone8ScreenShot2" className="export-iphone8">
                <Iphone82 />
            </div>
            <div id="iPhone8ScreenShot3" className="export-iphone8">
                <Iphone83 />
            </div>

            {
                isPAS && (
                    <Fragment>
                        <div id="iPhone8ScreenShot4" className="export-iphone8">
                            <Iphone84 />
                        </div>
                        <div id="iPhone8ScreenShot5" className="export-iphone8">
                            <Iphone85 />
                        </div>
                    </Fragment>
                )
            }

            <div id="iPhone8ScreenShot6" className="export-iphone8">
                <Iphone86 />
            </div>
        </div>
    );
});

PreviewRowIPhone8.displayName = 'PreviewRowIPhone8';

export default PreviewRowIPhone8;
