import React from 'react';

const AndroidFilter = () => (
    <svg width="27" height="26" viewBox="0 0 27 26" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M0.5 13C0.5 5.8203 6.3203 0 13.5 0C20.6797 0 26.5 5.8203 26.5 13C26.5 20.1797 20.6797 26 13.5 26C6.3203 26 0.5 20.1797 0.5 13ZM13.5 1.85714C7.34597 1.85714 2.35714 6.84597 2.35714 13C2.35714 19.154 7.34597 24.1429 13.5 24.1429C19.654 24.1429 24.6429 19.154 24.6429 13C24.6429 6.84597 19.654 1.85714 13.5 1.85714Z" />
        <path d="M13.7624 18.0409C13.3246 18.0409 12.9692 17.7848 12.9692 17.4679V11.1852C12.9692 10.8684 13.3246 10.6123 13.7624 10.6123C14.2002 10.6123 14.5555 10.8684 14.5555 11.1852V17.4679C14.5555 17.7848 14.2002 18.0409 13.7624 18.0409Z" />
        <path fillRule="evenodd" clipRule="evenodd" d="M13.7632 9.41439C14.2016 9.41439 14.5571 9.05894 14.5571 8.62047C14.5571 8.18199 14.2016 7.82654 13.7632 7.82654C13.3247 7.82654 12.9692 8.18199 12.9692 8.62047C12.9692 9.05894 13.3247 9.41439 13.7632 9.41439Z" />
    </svg>

);

export default AndroidFilter;
