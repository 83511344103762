const createTaskManager = ({ namePrefix, zip }) => {
    const Android = zip.folder('Android');
    const AndroidAssets = Android.folder('Assets');
    const AndroidFinalScreenshots = Android.folder('Final_Screenshots');
    const AndroidFinalScreenshotsfastlane = AndroidFinalScreenshots.folder('fastlane');
    const AndroidFinalScreenshotsfastlaneenUS = AndroidFinalScreenshotsfastlane.folder('en-US');
    const iOS = zip.folder('iOS');
    const iOSAppIcons = iOS.folder('App_icons');
    const iOSFinalScreenshots = iOS.folder('Final_Screenshots');
    const iOSFinalScreenshotsfastlane = iOSFinalScreenshots.folder('fastlane');
    const iOSFinalScreenshotsfastlaneenUS = iOSFinalScreenshotsfastlane.folder('en-US');

    return [
        {
            folder: AndroidFinalScreenshotsfastlaneenUS,
            height: 1656,
            width: 828,
            tasks: [
                {
                    elementId: 'AndroidScreenShot1',
                    fileName: '1_Home_1.png',
                },
                {
                    elementId: 'AndroidScreenShot2',
                    fileName: '1_Home_2.png',
                },
                {
                    elementId: 'AndroidScreenShot3',
                    fileName: '1_Home_3.png',
                },
                {
                    elementId: 'AndroidScreenShot4',
                    fileName: '1_Home_4.png',
                },
                {
                    elementId: 'AndroidScreenShot5',
                    fileName: '1_Home_5.png',
                },
                {
                    elementId: 'AndroidScreenShot6',
                    fileName: '1_Home_6.png',
                },
            ],
        },
        {
            folder: iOSFinalScreenshotsfastlaneenUS,
            height: 2688,
            width: 1242,
            tasks: [
                {
                    elementId: 'iPhoneXScreenShot1',
                    fileName: '1_Notch_Home_1.png',
                },
                {
                    elementId: 'iPhoneXScreenShot2',
                    fileName: '2_Notch_Home_2.png',
                },
                {
                    elementId: 'iPhoneXScreenShot3',
                    fileName: '3_Notch_Schedule.png',
                },
                {
                    elementId: 'iPhoneXScreenShot4',
                    fileName: '4_Notch_Home_4.png',
                },
                {
                    elementId: 'iPhoneXScreenShot5',
                    fileName: '5_Notch_Home_5.png',
                },
                {
                    elementId: 'iPhoneXScreenShot6',
                    fileName: '6_Notch_Achievements.png',
                },
            ],
        },
        {
            folder: iOSFinalScreenshotsfastlaneenUS,
            height: 2208,
            width: 1242,
            tasks: [
                {
                    elementId: 'iPhone8ScreenShot1',
                    fileName: '1_Plus_Home_1.png',
                },
                {
                    elementId: 'iPhone8ScreenShot2',
                    fileName: '2_Plus_Home_2.png',
                },
                {
                    elementId: 'iPhone8ScreenShot3',
                    fileName: '3_Plus_Schedule.png',
                },
                {
                    elementId: 'iPhone8ScreenShot4',
                    fileName: '4_Plus_Home_4.png',
                },
                {
                    elementId: 'iPhone8ScreenShot5',
                    fileName: '5_Plus_Home_5.png',
                },
                {
                    elementId: 'iPhone8ScreenShot6',
                    fileName: '6_Plus_Achievements.png',
                },
            ],
        },
        {
            elementId: 'AppIcon',
            folder: iOSAppIcons,
            tasks: [
                {
                    fileName: 'Icon-60@2x.png',
                    height: 120,
                    width: 120,
                },
                {
                    fileName: 'Icon-60@3x.png',
                    height: 180,
                    width: 180,
                },
                {
                    fileName: 'Icon-Notification@2x.png',
                    height: 40,
                    width: 40,
                },
                {
                    fileName: 'Icon-Notification@3x.png',
                    height: 60,
                    width: 60,
                },
                {
                    fileName: 'Icon-Small-40@2x.png',
                    height: 80,
                    width: 80,
                },
                {
                    fileName: 'Icon-Small-40@3x.png',
                    height: 120,
                    width: 120,
                },
                {
                    fileName: 'Icon-Small@2x.png',
                    height: 58,
                    width: 58,
                },
                {
                    fileName: 'Icon-Small@3x.png',
                    height: 87,
                    width: 87,
                },
                {
                    fileName: 'iTunesArtwork.png',
                    height: 1024,
                    width: 1024,
                },
            ],
        },
        {
            folder: AndroidAssets,
            tasks: [
                {
                    elementId: 'AppIcon',
                    fileName: `${namePrefix}-App-Icon.png`,
                    height: 512,
                    width: 512,
                },
                {
                    elementId: 'androidplaygraphic',
                    fileName: `${namePrefix}-Feature-Graphic.png`,
                    height: 500,
                    width: 1024,
                },
            ],
        },
    ];
};

const createTasksList = options => createTaskManager(options)
    .reduce((acc, curr) => {
        const {
            elementId, folder, height, tasks, width,
        } = curr;
        return [
            ...acc,
            ...tasks.map(task => ({
                elementId, folder, height, tasks, width, ...task,
            })),
        ];
    }, []);

export { createTaskManager };

export default createTasksList;
