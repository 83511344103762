import React from 'react';
import { useController, useFormContext } from 'react-hook-form';
import FormField from './FormField';

const TextInput = ({
    children,
    className,
    description,
    id,
    label,
    name,
    onBlur,
    onChange,
    onKeyDown,
    placeholder,
    required,
}) => {
    const { control, formState: { isSubmitted } } = useFormContext();
    const {
        field: {
            onChange: onc,
            onBlur: onb,
            ref,
            value,
        },
        meta: { invalid, isTouched },
    } = useController({
        name,
        control,
        rules: { required },
    });

    const isValid = !(isSubmitted && invalid) && (!isTouched || !invalid);

    return (
        <FormField
            className={className}
            description={description}
            isValid={isValid}
            label={label}
            name={name}
        >
            <div className="form-field__input">
                <input
                    className={`form-field__input-element form-field__input-element${isValid ? '' : '--error'}`}
                    id={id}
                    name={name}
                    onChange={(e) => {
                        if (onChange) onChange(e);
                        onc(e.target.value);
                    }}
                    onKeyDown={(e) => {
                        if (onKeyDown) onKeyDown(e);
                    }}
                    onBlur={(e) => {
                        if (onBlur) onBlur(e);
                        onb(e.target.value);
                    }}
                    placeholder={placeholder || 'Text'}
                    ref={ref}
                    required={required}
                    type="text"
                    value={name.includes('Color') ? value.replace('#', '') : value}
                />
                {children}
            </div>
        </FormField>
    );
};

export default TextInput;
