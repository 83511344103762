import React, { memo, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { SHOW_FORM } from '../_store/reducer';
import Form from './form/Form';

const LeftContainer = memo(() => {
    const dispatch = useDispatch();
    const appState = useSelector(state => state.appState);
    const initialized = useSelector(({ uiState }) => uiState.initialized);
    const isFormVisible = useSelector(({ uiState }) => uiState.isFormVisible);
    const defaultValues = useMemo(() => appState, [initialized]);
    const formMethods = useForm({ defaultValues, mode: 'onBlur' });

    const setIsFormVisible = () => {
        dispatch({
            type: SHOW_FORM,
            payload: { isFormVisible: !isFormVisible },
        });
    };

    return (
        <section className="content content-left">
            {initialized && <Form {...formMethods} />}
            <button
                type="button"
                className="btn-tab"
                onClick={setIsFormVisible}
            >
                {isFormVisible ? 'Hide' : 'Show'}
            </button>
        </section>
    );
});

LeftContainer.displayName = 'LeftContainer';

export default LeftContainer;
