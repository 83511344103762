import React, { memo } from 'react';
import Achievements from '../../views/Achievements';
import iPhoneXFront from '../../../AppAssets/Phones/iphoneX_upfront.png';

const IphoneX6 = memo(() => (
    <div>
        <div className="screenshot screenshot-6">
            <h2>Celebrate your workout achievements and share them with your friends!</h2>
            <div className="phone-outer">
                <img className="phoneMock" src={iPhoneXFront} alt="iPhone X" />
                <Achievements />
            </div>
        </div>

    </div>
));

IphoneX6.displayName = 'IphoneX6';
export default IphoneX6;
