import React, { memo } from 'react';
import { useSelector } from 'react-redux';
import HomeScreen from '../../AppAssets/Phones/Home.jpg';
import defaultMark from '../../AppAssets/Icons/DefaultIcon.png';

const IosHome = memo(() => {
    const appIconLogoMark = useSelector(({ appState }) => appState.appIconLogoMark);
    const lightLogoMark = useSelector(({ appState }) => appState.lightLogoMark);
    const brandName = useSelector(({ appState }) => appState.brandName);
    const appIconBackColor = useSelector(({ appState }) => appState.appIconBackColor);

    return (
        <div>
            <div className="view view-ios home">
                <img src={HomeScreen} alt="" />
                <div className="iconWrap">
                    <div
                        className="app-icon"
                        style={{
                            backgroundColor: appIconBackColor,
                        }}
                    >
                        <div
                            className="app-icon__image"
                            style={{
                                backgroundImage: `url("${appIconLogoMark || lightLogoMark || defaultMark}")`,
                            }}
                        />
                    </div>
                    <p>{brandName || 'Mariana Tek'}</p>
                </div>
            </div>
        </div>
    );
});

IosHome.displayName = 'IosHome';

export default IosHome;
